import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Label, Input, Alert } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import { matchPath } from "react-router"
import Navbar from './Navbar'
import Footer from '../Footer'
import Section from './Section'
import Parse from 'parse'
import Moment from 'moment'
import ApplyJobModal from "./ApplyJobModal"

const PageJobDetailThree = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/liltriangle-job-detail/:jobId",
        exact: true,
        strict: false,
    })

    const jobId = match && match.params.jobId

    const [applyJobPopup, setApplyJobPopup] = useState(false)
    const [jobDetails, setJobDetails] = useState()
    const [isLoading, setIsLoading] = useState(false)



    useEffect(() => {
        console.log(jobId)
        setIsLoading(true)
        Parse.Cloud.run("function_getJobNotificationDetails", {
            jobId: jobId,
        }).then((result) => {
            if (result) {
                console.log(result)
                setJobDetails(result)
            }
        },
            (error) => {
                console.log(error)
            }
        )
        setIsLoading(false)
    }, [jobId])

    function getTimeStringForDate(date) {
        return Moment(date).format("MMM Do YY")
    }

    return (
        <React.Fragment>
            <ApplyJobModal
                applyJobPopup={applyJobPopup}
                onClosePopUp={() => {
                    setApplyJobPopup(!applyJobPopup)
                }}
                selectedJob={jobDetails}
            />
            <Navbar />
            <Section title={"Job Details"} />
            <section className="mt-5">
                <Container>
                    <Row>
                        <Col lg={4} md={6}>
                            <Card className="border-0 shadow rounded bg-white p-4 sticky-bar">
                                <div className="mt-4">
                                    <h4 className="title mb-3"> {jobDetails && jobDetails.attributes.designation} </h4>
                                    <p><b>School: </b>{jobDetails && jobDetails.attributes.schoolName}</p>
                                    <p><b>Qualification: </b>{jobDetails && jobDetails.attributes.qualifications && jobDetails && jobDetails.attributes.qualifications.length > 0 && (
                                        <Link to="#" className="text-dark">{jobDetails && jobDetails.attributes.qualifications.join(", ")}</Link>
                                    )}</p>
                                    <ul className="list-unstyled mb-0">
                                        <li className="list-inline-item mb-0 text-primary me-3">
                                            <i className="mdi mdi-map-marker text-warning me-2"></i>
                                            {jobDetails && jobDetails.attributes.area}, {jobDetails && jobDetails.attributes.location}
                                        </li>{" "}
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <div className="sidebar border-0">
                                <h5 className="mb-0">Job Information</h5>
                                <ul className="list-unstyled mb-0 mt-4">
                                    <li className="list-inline-item px-3 py-2 shadow rounded text-start me-1">
                                        <div className="d-flex widget align-items-center">
                                            <FeatherIcon
                                                icon="user-check"
                                                className="fea icon-ex-md me-3"
                                            />
                                            <div className="flex-1">
                                                <h6 className="widget-title mb-0">
                                                    {"Employee Type"}:
                                                </h6>
                                                <small className="text-primary mb-0">
                                                    {"Full Time"}
                                                </small>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-inline-item px-3 py-2 shadow rounded text-start me-1">
                                        <div className="d-flex widget align-items-center">
                                            <FeatherIcon
                                                icon="briefcase"
                                                className="fea icon-ex-md me-3"
                                            />
                                            <div className="flex-1">
                                                <h6 className="widget-title mb-0">
                                                    {"Experience"}:
                                                </h6>
                                                <small className="text-primary mb-0">
                                                    {jobDetails && jobDetails.attributes.experience} Years
                                                </small>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-inline-item px-3 py-2 shadow rounded text-start me-1">
                                        <div className="d-flex widget align-items-center">
                                            <FeatherIcon
                                                icon="clock"
                                                className="fea icon-ex-md me-3"
                                            />
                                            <div className="flex-1">
                                                <h6 className="widget-title mb-0">
                                                    {"Date posted"}:
                                                </h6>
                                                <small className="text-primary mb-0">
                                                    {getTimeStringForDate(jobDetails && jobDetails.attributes.startDate)}
                                                </small>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-4">
                                <h5>Job Description: </h5>
                                <p className="text-muted">
                                    {jobDetails && jobDetails.attributes.description && (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: jobDetails.attributes.description
                                            }}
                                        />

                                    )}
                                </p>

                                <h5>Roles & Responsibilities: </h5>
                                <p className="text-muted">
                                    {jobDetails && jobDetails.attributes.roles && (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: jobDetails.attributes.roles
                                            }}
                                        />

                                    )}
                                </p>
                                <div className="mt-4">
                                    <Button className="btn " color='primary'
                                        onClick={() => {
                                            setApplyJobPopup(true)
                                        }}>
                                        Apply Now <i className="mdi mdi-send"></i>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="mt-5 ">
                <Footer />
            </section>
        </React.Fragment>
    )
}

export default PageJobDetailThree
