import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"

// import Images
import amico from "../../../assets/images/LilTriangleImages/interactiondesign-amico.svg"

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-4">
                  Payments Alerts
                </h4>

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Payment Alerts
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Integrate Statuspage with DataDog, New Relic, Librato or
                  Pingdom to update your page as soon as an issue is detected.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Online Payment
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Integrate Statuspage with Opsgenie, PagerDuty, VictorOps, or
                  xMatters to update status directly from your alerting app.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Automation via API or email
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Use our REST API to programmatically update your status page
                  by writing your own integration, or automatically update the
                  status of components by triggering en email with a subject
                  line containing the words “UP” or “DOWN”.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
