import React from 'react'
import { Redirect } from "react-router-dom";

import RedirectComponent from '../pages/RedirectComponent';
//LilTriangle
import LilTriangleIndex from "../pages/A_LilTriangle/index";
import LiltriangleFooter from "../pages/A_LilTriangle/Footer";
import LilTrianglePayment from '../pages/A_LilTriangle/LilTrianglePayments/index';
import LilTriangleCommunication from "../pages/A_LilTriangle/LilTriangleCommunication/index"
import LiltriangleCustomers from "../pages/A_LilTriangle/LilTriangleClints/index";
import LilTriangleTerms from "../pages/A_LilTriangle/TermsOfuse/index";
import LilTriangleRefundPolicy from "../pages/A_LilTriangle/RefundPolicy/index";
import LilTrianglePrivacyPolicy from "../pages/A_LilTriangle/PrivacyPolicy/index";
import LilTriangleParentLogin from "../pages/A_LilTriangle/ParentLogin/index";
import LilTriangleAttendence from "../pages/A_LilTriangle/LilTriangleAttendence/index";
import LilTriangleDaycare from "../pages/A_LilTriangle/LilTriangleDaycare/index";
import LilTriangleCCTV from "../pages/A_LilTriangle/LilTriangleCCTV/index";
import LilTriangleTransport from "../pages/A_LilTriangle/LilTriangleTransport/index";
import LilTriangleLMS from "../pages/A_LilTriangle/LilTriangleLMS/index";
import LilTriangleEnquiry from "../pages/A_LilTriangle/LilTriangleEnquiry/index";
import LilTriangleExpense from "../pages/A_LilTriangle/LilTriangleExpense/index";
import LilTriangleJobList from "../pages/A_LilTriangle/LilTriangleCareers/JobList"
import LilTriangleJobDetailPage from "../pages/A_LilTriangle/LilTriangleCareers/JobDetailPage"


const routes = [
  //routes without Layout

  //LilTriangle
  { path: "/liltriangle-index", component: LilTriangleIndex, isWithoutLayout: true },
  { path: "/liltriangle-payment", component: LilTrianglePayment, isWithoutLayout: true},
  { path: "/liltriangle-communication", component: LilTriangleCommunication, isWithoutLayout: true },
  { path: "/liltriangle-footer", component: LiltriangleFooter, isWithoutLayout: true },
  { path: "/liltriangle-customers", component: LiltriangleCustomers, isWithoutLayout: true },
  { path: "/liltriangle-termsofUse", component: LilTriangleTerms, isWithoutLayout:true},
  { path: "/liltriangle-refundpolicy", component: LilTriangleRefundPolicy, isWithoutLayout:true},
  { path: "/liltriangle-privacyPolicy", component:LilTrianglePrivacyPolicy, isWithoutLayout:true},
  { path: "/liltriangle-parentLogin", component:LilTriangleParentLogin, isWithoutLayout:true},
  { path: "/liltriangle-attendance", component: LilTriangleAttendence, isWithoutLayout: true },
  { path: "/liltriangle-daycare", component: LilTriangleDaycare, isWithoutLayout: true },
  { path: "/liltriangle-expense", component: LilTriangleExpense, isWithoutLayout: true },
  { path: "/liltriangle-cctv", component: LilTriangleCCTV, isWithoutLayout: true },
  { path: "/liltriangle-transport", component: LilTriangleTransport, isWithoutLayout: true },
  { path: "/liltriangle-enquiry", component: LilTriangleEnquiry, isWithoutLayout: true },
  { path: "/liltriangle-lms", component: LilTriangleLMS, isWithoutLayout: true },
  { path: "/liltriangle-jobs", component: LilTriangleJobList, isWithoutLayout: true},
  { path: "/liltriangle-job-detail/:jobId", component: LilTriangleJobDetailPage, isWithoutLayout: true},

  
  { path: "/register", component: RedirectComponent, isWithoutLayout: true },



  { path: "/:id", component: ()=>(<Redirect to="/"/> )},
  

  { path: "/", component: LilTriangleIndex, isWithoutLayout: true, exact: true },

];

export default routes;
