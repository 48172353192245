import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"

// import Images
import amico from '../../../assets/images/LilTriangleImages/communication feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Instant Alerts
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  With the lil Triangle App, Preschools can send instant alerts and notifications about important events, Preschool closures, and urgent announcements.                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Calendar Updates
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Stay organized with our integrated calendar feature. Share updates on Preschools events & holidays, parent-teacher meeting, and important dates directly on app                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Share  Photos and Videos
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Experience your child's Preschool journey like never before. The lil Triangle App allows Preschools to share photos and videos of classroom activities, performances, and special moments.                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Homework Tracking
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Keep track of child's assignments  effortlessly. The app provides a convenient platform for teachers to upload homework and assignments.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Notices and Announcements
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  The app enables Preschools to share notices, newsletters, and announcements directly to mobile device, ensuring parents are always up-to-date.                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
