import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Container } from "reactstrap"
import ScrollspyNav from "../scrollSpy"

import RightSidebar from '../../../components/Layout/RightSidebar'

//React Drawer
import ReactDrawer from 'react-drawer'
import 'react-drawer/lib/react-drawer.css'

//Import Icons
import FeatherIcon from "feather-icons-react"
import LilTriangleLog from "../../../assets/images/LilTriangleImages/logoWithName.webp"

class NavbarPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "benefits", navheading: "Benefits" },
        { id: 3, idnm: "features", navheading: "Features" },
      ],
      open: false,
      position: 'right',
    }
    this.toggleLine = this.toggleLine.bind(this)
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this)
    this.onDrawerClose = this.onDrawerClose.bind(this)
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }
  toggleRightDrawer = () => {
    this.setState({ position: 'right' })
    this.setState({ open: !this.state.open })
  }
  onDrawerClose = () => {
    this.setState({ open: false })
  }

  initMenu() {
    this.activateMenu()
  }

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = this.state.navItems.map((item) => {
      return item.idnm
    })

    return (
      <div className="">

      </div>
    )
  }
}

export default NavbarPage

