import React, { Component, Suspense } from "react"
import { withRouter } from "react-router-dom"
import Tagline from "./Tagline"
import ThemeSwitcher from "./ThemeSwitcher"
import BackToTop from "./backToTop"


const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  )
}

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          {this.props.children}
          <BackToTop />
          <ThemeSwitcher />
        </Suspense>

      </React.Fragment>
    )
  }
}

export default withRouter(Layout)
