import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader, Collapse, Button } from "reactstrap"
import FeatherIcon from "feather-icons-react"
import { Link } from "react-router-dom"
import StarRatings from "react-star-ratings"
// import app from "../../../assets/images/LilTriangleImages/app-store.webp"
import app from "../../../assets/images/LilTriangleImages/app.webp"
// import playstore from "../../../assets/images/LilTriangleImages/play-store.webp"
import playstore from "../../../assets/images/LilTriangleImages/playstore.webp"
import logoWithName from "../../../assets/images/LilTriangleImages/logoWithName.webp"
import qrcode from "../../../assets/images/LilTriangleImages/qrcode.jpeg"
// import LogoLight from '../../assets/images/LilTriangleImages/logoLight.webp'


function ParentLogin() {

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)


    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768)
        }

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    let appLink = "https://onelink.to/liltriangle"


    return (

        <React.Fragment>
            
            <Row className="justify-content-center mt-3">
                <Col lg={6} className="col-12 mt-0 mb-3 text-center">
                    <Link to="#" className="d-inline-block">
                        <img src={logoWithName} height="70" alt="Logo" />
                    </Link>
                </Col>
                <Col lg={12} className="text-center">
                    <div className="mt-0">
                        <h3 className="mt-0">
                        <i className="uil uil-mobile-android display-4 text-black title-dark ms-3"></i>
                            Download our App now
                        </h3>
                    </div>
                </Col>



                <section className="bg-half-80 bg-light w-100">
                    <div className="flex-1 content p-3 shadow rounded bg-white position-relative">

                        {isMobileView ? <Container className={"mt-0"} style={{ paddingLeft: '20px', paddingRight: '20px' }}>


                            <Row className="d flex justify-content-between">

                                <Col xs={6} className="text-center py-5">
                                    {/* <div className="flex-1 content p-3 shadow rounded bg-white position-relative"> */}
                                    <img src={playstore} className="m-3" height="40" alt="Download on App Store" /><br />
                                    {/* <b>
                                        <span className="py-4">
                                            <b className='' style={{ marginBottom: '30px' }}>4.8</b>
                                        </span>
                                    </b><br />
                                    <StarRatings
                                        items={3}
                                        rating={4.8}
                                        starRatedColor="#F17425"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="15px"
                                        starSpacing="1px"
                                    /> */}
                                    {/* </div> */}
                                </Col>

                                <Col xs={6} className="text-center py-5">
                                    {/* <div className="flex-1 content p-3 shadow rounded bg-white position-relative"> */}
                                    <img src={app} className="m-3" height="40" alt="Download on App Store" /><br />
                                    {/* <b><span className="py-4">4.6</span></b><br />
                                    <StarRatings
                                        items={3}
                                        rating={4.6}
                                        starRatedColor="#F17425"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="15px"
                                        starSpacing="1px"
                                    /> */}
                                    {/* </div> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center py-4">
                                    {/* <div className="flex-1 content p-3 shadow rounded bg-white position-relative"> */}

                                    <img src={qrcode} className="m-3" height="100" alt="Scan above QR CODE" /><br />
                                    <p>Scan & Download App</p>
                                    {/* </div> */}
                                </Col>
                            </Row>

                        </Container>
                            // </div>

                            :
                            <Container className={"xl mt-10"} >
                                {/* <Container fluid className={"xs mt-10"} > */}
                                <div className="flex-1 content p-3 shadow rounded bg-white position-relative">

                                    <div className="rounded shadow bg-light p-3"  >
                                        <Row className="justify-content-center">
                                            {/* <Col lg={4} md={4} className="col-lg-4 col-md-4">
                                    <Col lg={3} md={4} className="col-lg-4 col-md-4">

                                        <h5 className="mb-0" style={{ marginTop: '60px' }}>
                                            <strong>lil Triangle</strong> is highly rated, across the platforms with 10000+ ratings and 2Lakh+ Downloads.
                                        </h5>
                                    </Col> */}


                                            <Col lg={5} md={4} xs={4} className="text-center py-4">


                                                {/* <div
                                            className="flex-1 content p-3 shadow rounded bg-white position-relative"
                                        > */}
                                                <a
                                                    href="https://apps.apple.com/in/app/liltriangle/id956406796"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >


                                                    <img src={app} className="m-3" height="50" alt="Download on App Store" /> </a>{" "}<br />
                                                    <b><span className="py-4">4.6</span>
                                                    <br></br></b>
                                                <StarRatings
                                                    items={3}
                                                    rating={4.6}
                                                    starRatedColor="#F17425"
                                                    numberOfStars={5}
                                                    name="rating"
                                                    starDimension="15px"
                                                    starSpacing="1px"
                                                /> 
                                                {/* </div> */}
                                            </Col>
                                            <Col lg={5} md={4} xs={4} className="text-center py-4">


                                                {/* <div
                                            className="flex-1 content p-3 shadow rounded bg-white position-relative"
                                        > */}
                                                <a
                                                    href="https://play.google.com/store/apps/details?id=com.junior&hl=en_US"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={playstore} className="m-3" height="50" alt="Download on App Store" /></a><br />
                                                <b>
                                                    <span className="py-4">
                                                        <b className='' style={{ marginBottom: '30px' }}>4.8</b>
                                                    </span>
                                                </b><br />
                                                <StarRatings
                                                    items={3}
                                                    rating={4.8}
                                                    starRatedColor="#F17425"
                                                    numberOfStars={5}
                                                    name="rating"
                                                    starDimension="15px"
                                                    starSpacing="1px"
                                                /> 
                                                {/* </div> */}
                                            </Col>
                                            <Col lg={5} md={4} xs={4} className="text-center py-3">


                                                {/* <div className="flex-1 content p-4 shadow rounded bg-white position-relative"> */}


                                                <img src={qrcode} className="mt-1 mb-2" height="255" alt="Scan above QR CODE" /><br />
                                                <h6>Scan & Download App</h6>
                                                {/* </div> */}

                                            </Col>
                                            {/* <Row>
                                        <div className="text-center">
                                            <Link to="#" onClick={() => {
                                                window.open("https://onelink.to/liltriangle/")
                                            }} className="btn btn-success waves-effect waves-light mr-2">
                                                <i className="">Download App Poster</i>

                                            </Link>
                                            <Link to="#" onClick={() => window.open("https://onelink.to/liltriangle/liltriangle-app-poster","_blank")} className="btn btn-success waves-effect waves-light mr-2"> 

                                        </div>
                                    </Row> */}
                                            {/* <Row>
                                        <div className="text-center">
                                            <a href={ "https://onelink.to/liltriangle"} 
                                            className="btn btn-success waves-effect waves-light mr-2" 
                                            target="_blank" 
                                            rel="noopener noreferrer">
                                                <i className="">Download App Poster</i>
                                            </a>
                                        </div>
                                    </Row>  */}



                                        </Row>
                                    </div>
                                </div>
                            </Container>}
                    </div>

                </section>
            </Row>
        </React.Fragment>
    )
}

export default ParentLogin
