import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import StarRatings from "react-star-ratings";
import app from "../../assets/images/LilTriangleImages/app-store.webp";
import playstore from "../../assets/images/LilTriangleImages/play-store.webp";
import WebsiteImage from '../../assets/images/LilTriangleImages/websiteImage2.webp'
const Section = () => {


  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <React.Fragment>
      <section className={isMobileView?"bg-half-100 d-table w-100  overflow-hidden":"bg-half-100 d-table w-100 home-wrapper overflow-hidden"} id="home">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <div className="title-heading" style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px' } : {}}>
                <span className="badge rounded-pill bg-soft-primary m-1"><b>Preschool</b></span>
                <span className="badge rounded-pill bg-soft-primary m-1"><b>School</b></span>
                <span className="badge rounded-pill bg-soft-primary m-1"><b>Daycare</b></span>
                <span className="badge rounded-pill bg-soft-primary m-1"><b>Institutes</b></span>
                <h2 className=" my-3">Easy, Powerful, Everything you need for your preschool <span className="">& daycare</span></h2>
                <p className="para-desc text-muted mb-0">Streamline, Simplify and Succeed: Your Preschool and Daycare Effortlessly Managed with Lil Triangle, which is highly rated, across the platforms.</p>
                <p className="text-muted mb-0 mt-3">Existing account{" "}
                  <a href="https://dashboard.liltriangle.com/login" target='_blank' rel="noreferrer" className="text-primary ms-2 h6 mb-0">Login{" "}
                    <i><FeatherIcon icon="arrow-right" className="fea icon-sm" /></i>
                  </a>
                </p>
                <p className="text-muted mb-0 mt-3">Don't have an account yet?{" "}
                  <a href="https://dashboard.liltriangle.com/register" target='_blank' rel="noreferrer" className="text-primary ms-2 h6 mb-0">Register{" "}
                    <i><FeatherIcon icon="arrow-right" className="fea icon-sm" /></i>
                  </a>
                </p>
              </div>
            </Col>
            <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div >
                <img src={WebsiteImage} className="img-fluid" alt="" />
              </div>
            </Col>
            <div
              className="home-dashboard onepage-hero"
              data-aos="fade-up"
              data-aos-duration="2200"
            >
            </div>
          </Row>
        </Container>
        {isMobileView ? <Container className={"mt-100 mt-60"} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <div className="rounded shadow bg-light p-4"  >
            <Row className="justify-content-center">
              <Col>
                <h5 className="mb-0" style={{ marginTop: '60px' }}>
                  <strong>lil Triangle</strong> is highly rated, across the platforms with 10000+ ratings and 2Lakh+ Downloads.
                </h5>
              </Col>
            </Row>
            <Row className="d flex justify-content-between">
              <Col className="text-center py-4">
                <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                  <img src={playstore} className="m-3" height="50" alt="Download on App Store" /><br />
                  <b>
                    <span className="py-4">
                      <b className='' style={{ marginBottom: '30px' }}>4.8</b>
                    </span>
                  </b><br />
                  <StarRatings
                    items={3}
                    rating={4.8}
                    starRatedColor="#F17425"
                    numberOfStars={5}
                    name="rating"
                    starDimension="15px"
                    starSpacing="1px"
                  />
                </div>
              </Col>

              <Col className="text-center py-4">
                <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                  <img src={app} className="m-3" height="50" alt="Download on App Store" /><br />
                  <b><span className="py-4">4.6</span></b><br />
                  <StarRatings
                    items={3}
                    rating={4.6}
                    starRatedColor="#F17425"
                    numberOfStars={5}
                    name="rating"
                    starDimension="15px"
                    starSpacing="1px"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
          :
          <Container className={"mt-100 mt-60"} >
            <div className="rounded shadow bg-light p-4"  >
              <Row className="justify-content-center">
                <Col lg={4} md={4} className="col-lg-4 col-md-4">
                  <h5 className="mb-0" style={{ marginTop: '60px' }}>
                    <strong>lil Triangle</strong> is highly rated, across the platforms with 10000+ ratings and 2Lakh+ Downloads.
                  </h5>
                </Col>
                <Col lg={4} md={4} xs={4} className="text-center py-4">
                  <div
                    className="flex-1 content p-3 shadow rounded bg-white position-relative"
                  >
                    <img src={playstore} className="m-3" height="50" alt="Download on App Store" /><br />
                    <b>
                      <span className="py-4">
                        <b className='' style={{ marginBottom: '30px' }}>4.8</b>
                      </span>
                    </b><br />
                    <StarRatings
                      items={3}
                      rating={4.8}
                      starRatedColor="#F17425"
                      numberOfStars={5}
                      name="rating"
                      starDimension="15px"
                      starSpacing="1px"
                    />
                  </div>
                </Col>
                <Col lg={4} md={4} xs={4} className="text-center py-4">
                  <div
                    className="flex-1 content p-3 shadow rounded bg-white position-relative"
                  >
                    <img src={app} className="m-3" height="50" alt="Download on App Store" /><br />
                    <b><span className="py-4">4.6</span>
                      <br></br></b>
                    <StarRatings
                      items={3}
                      rating={4.6}
                      starRatedColor="#F17425"
                      numberOfStars={5}
                      name="rating"
                      starDimension="15px"
                      starSpacing="1px"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>}
      </section>
    </React.Fragment>
  );
};

export default Section;