import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Container, Row, Col, Form, UncontrolledTooltip } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import LogoLight from '../../assets/images/LilTriangleImages/logoLight.webp'
import app from "../../assets/images/LilTriangleImages/app.webp"
import playstore from "../../assets/images/LilTriangleImages/playstore.webp"

const Footer = (props) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const grid1 = [
    // { title: 'About us', link: '#' },
    // { title: 'Services', link: '#' },
    // { title: 'Testimonial', link: '#' },
    { title: 'Home', link: '#' },
    { title: 'Register', link: 'https://dashboard.liltriangle.com/register' },
    { title: 'Login', link: 'https://dashboard.liltriangle.com/login' },
    { title: 'Parent Login', link: '/liltriangle-parentlogin'}

  ]

  const grid2 = [
    { title: 'Terms of Use', link: '/liltriangle-termsofUse' },
    { title: 'Privacy Policy', link: '/liltriangle-privacyPolicy' },
    { title: 'Refund Policy', link: '/liltriangle-refundpolicy' },
  ]

  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <div className='footer-py-60'>
            <Row className="align-items-center">
              <Col md={7}>
                <div className="section-title">
                  <div className="d-flex">
                    <i className="uil uil-mobile-android display-4 text-light title-dark"></i>
                    <div className="flex-1 ms-md-4 ms-3">
                      <h4 className="fw-bold text-light title-dark mt-2">
                        Download our app now !
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={5} className="mt-4 mt-sm-0">
                <div className="text-md-end ms-5 ms-sm-0">
                  <a
                    href="https://apps.apple.com/in/app/liltriangle/id956406796"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={app} className="m-1" height="50" alt="Download on App Store" />
                  </a>{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.junior&hl=en_US"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playstore} className="m-1" height="50" alt="Get it on Google Play" />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className={props.isBorderLine ? "footer-py-60 footer-border" : "footer-py-60"}>
              <Row>
                <Col lg={4} className="col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                  <Link to="#" className="logo-footer">
                    <img src={LogoLight} height="30" alt="" />
                  </Link>
                  <p className={props.isLight ? 'mt-4 text-muted' : 'mt-4'}>
                    India's most trusted preschool and daycare app, seamlessly connecting schools and parents for effortless communication and operations.
                  </p>
                  <ul className='list-unstyled'>
                    <li className="list-inline-item mx-2 mt-3">
                      <a
                        target='_blank'
                        id="facebook"
                        href="https://www.facebook.com/lilTriangle/"
                        className="btn btn-icon btn-pills btn-lg btn-primary"
                        rel="noreferrer"
                      >
                        <i>
                          <FeatherIcon icon="facebook" className="icons" />
                        </i>
                      </a>
                    </li>
                    <UncontrolledTooltip target="facebook" placement="top">
                      Facebook
                    </UncontrolledTooltip>
                    <li className="list-inline-item mx-2 mt-3">
                      <a
                        href="https://www.instagram.com/liltriangleapp/"
                        id="instagram"
                        className="btn btn-icon btn-pills btn-lg btn-danger me-1"
                        target='_blank'
                        rel="noreferrer"
                      >
                        <i>
                          <FeatherIcon icon="instagram" className="icons" />
                        </i>
                      </a>
                    </li>
                    <UncontrolledTooltip target="instagram" placement="top">
                      instagram
                    </UncontrolledTooltip>
                    <li className="list-inline-item mx-2 mt-3">
                      <a
                        href="https://www.linkedin.com/company/lil-triangle/"
                        id="linkedin"
                        className="btn btn-icon btn-pills btn-lg btn-secondary  me-1"
                        target='_blank'
                        rel="noreferrer"
                      >
                        <i>
                          <FeatherIcon icon="linkedin" className="icons" />
                        </i>
                      </a>
                      <UncontrolledTooltip target="linkedin" placement="top">
                        LinkedIn
                      </UncontrolledTooltip>
                    </li>
                    <li className="list-inline-item mx-2 mt-3">
                      <a
                        href="https://twitter.com/triangle_lil"
                        id="twitter"
                        className="btn btn-icon btn-pills btn-lg btn-info me-1"
                        target='_blank'
                        rel="noreferrer"
                      >
                        <i>
                          <FeatherIcon icon="twitter" className="icons" />
                        </i>
                      </a>
                    </li>
                    <UncontrolledTooltip target="twitter" placement="top">
                      twitter
                    </UncontrolledTooltip>
                  </ul>
                </Col>

                <Col lg={2} md={4} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className={props.isLight ? 'text-dark footer-head' : 'text-light footer-head'}>
                    Company
                  </h5>
                  <ul className="list-unstyled footer-list mt-4">
                    {grid1.map((grid, key) => (
                      <li key={key}>
                        <a
                          href={grid.link}
                          rel="noopener noreferrer"
                          className={props.isLight ? 'text-muted' : 'text-foot'}
                        >
                          <i className="uil uil-angle-right-b me-1"></i> {grid.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>

                <Col lg={3} md={4} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className={props.isLight ? 'text-dark footer-head' : 'text-light footer-head'}>
                    Useful Links
                  </h5>
                  <ul className="list-unstyled footer-list mt-4">
                    {grid2.map((grid, key) => (
                      <li key={key}>
                        <Link
                          to={grid.link}
                          className={props.isLight ? 'text-muted' : 'text-foot'}
                          target="_blank"
                        >
                          <i className="uil uil-angle-right-b me-1"></i>{' '}
                          {grid.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col>

                {!isMobileView && <Col lg={3} md={4} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className={props.isLight ? 'text-dark footer-head' : 'text-light footer-head'}>
                    Book A Demo
                  </h5>
                  <p className="mt-4">Schedule a live demo with our representative.</p>
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <Link to="#" onClick={() => window.open("https://calendly.com/liltriangle/liltriangle", "_blank")}>
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submitsubscribe"
                              name="send"
                              className={"btn btn-soft-primary"}
                              value="Book Demo"
                            />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </Col>}
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="footer-py-30 footer-bar">
          <Container className="text-center">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="text-sm-start">
                  <p className="mb-0">
                    © {(new Date().getFullYear())} lil Triangle. Design with {" "}
                    <i className="mdi mdi-heart text-danger"></i> by {" "}
                    <Link
                      to="https://themesbrand.in/"
                      target="_blank"
                      className="text-reset"
                      rel="noopener noreferrer"
                    >
                      lil Triangle
                    </Link>.
                  </p>
                </div>
              </Col>

              <Col sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul className="list-unstyled text-sm-end mb-0 d-flex gap-1 flex-wrap justify-content-sm-end">
                  <p className="mb-0">lil Triangle, a Product of Slate and Chalk Innovations Pvt Ltd</p>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
