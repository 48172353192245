import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"
import FeatureSlider from '../FeatureSlider'

// import Images
import amico from '../../../assets/images/LilTriangleImages/payment feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Invoice Creation & Notification
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Preschools can easily generate invoices for various fees and automatic notifications are sent to parents to inform them about upcoming fees,
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Payment Gateway Integration
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Integrated with a highly secure payment gateway helps parents to make payment conveniently  with a single click
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Automated Reminders
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Parents receive timely reminders about upcoming fee due dates, reducing the chances of missed payments.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Automated Receipts
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Receipts are automatically generated and sent to parents upon successful payment, reducing administrative work for the Preschool
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Reporting and Analytics
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Preschools can access comprehensive reports and analytics with a single click, providing valuable insights into fee collection and financial data.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
