import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"
import FeatureSlider from '../FeatureSlider'

// import Images
import amico from '../../../assets/images/LilTriangleImages/LMS feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Data Highly Secured
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  lil Triangle App offers a highly secure Learning Management System (LMS) for Preschools to upload and manage their entire curriculum.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Child Progress Tracking
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  The app provides tools for teachers to record and assess the progress of each child's skills and educational development.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Individualized Reports
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                Preschools can generate individualized progress reports for parents, highlighting areas of improvement and achievements.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Real-Time Progress Tracking
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  The LMS allows Preschools to track the academic progress of each student, providing insights into their strengths and areas that need improvement.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Customized Reporting
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Generate custom reports on student progress, making it easier for teachers and parents to understand a child's academic and skill development journey.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
