import React from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader, Collapse } from "reactstrap"
import FeatherIcon from "feather-icons-react"
import { Link } from "react-router-dom"

function TermsOfUse() {
    return (
        <React.Fragment>
            <section className="bg-half-100 bg-light d-table w-100">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} className="text-center">
                            <div className="pages-heading">
                                {/* <img src={LilTriangleLog} height="35" alt="" /> */}
                                <h4 className='mt-4'>
                                    END USER LICENSE AGREEMENT
                                </h4>
                                <p className='mt-3' style={{ fontSize: "15px" }}><b>PUBLISHED 25 JAN 2016 5 MINUTE READ</b></p>
                                <div className='mt-4'>
                                    <span className='mr-2' style={{ fontSize: "14px" }}>IMPORTANT :</span>
                                    <span className='text-muted' style={{ fontSize: "14px" }}>PLEASE READ THIS LICENSE CAREFULLY BEFORE USING THIS SOFTWARE.</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="position-breadcrumb">
                        <nav aria-label="breadcrumb" className="d-inline-block">
                            <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>{" "}
                                <li className="breadcrumb-item active" aria-current="page">Terms Of Use</li>
                            </ul>
                        </nav>
                    </div>
                </Container>
            </section>

            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={9}>
                            <Card className="shadow border-0 rounded">
                                <CardBody>
                                    <h5 className="card-title">1. LICENSE</h5>
                                    <p className="text-muted">
                                        By installing and/or using lil Triangle ("Software") containing this
                                        software, you agree that this End User License Agreement (EULA) is a
                                        legally binding and valid contract and agree to be bound by it. You agree
                                        to abide by the intellectual property laws and all of the terms
                                        and conditions of this Agreement. </p>
                                    <p className="text-muted">Your use of lil Triangle indicates your acceptance of this license agreement and warranty.</p>
                                    <p className="text-muted">Subject to the terms of this Agreement, LittleTriangle Pvt.Ltd grants
                                        to you a limited, non-exclusive, non-transferable license, without right to sub-license,
                                        to use lil Triangle in accordance with this Agreement and any other written agreement with LittleTriangle Pvt.Ltd.
                                        Oyoo Design Studio does not transfer the title of lil Triangle to you the license granted to you is not a sale.
                                        This agreement is a binding legal agreement between LittleTriangle Pvt.Ltd and the purchasers or users of lil Triangle.</p>
                                    <p className="text-muted">If you do not agree to be bound by this agreement, lil Triangle users from your school will be unsubscribe from system and all users need to remove lil Triangle app from their devices. </p>


                                    <h5 className="card-title">2. DISTRIBUTION</h5>
                                    <p className="text-muted">
                                        lil Triangle and the license herein granted shall not be copied, shared, distributed, re-sold, offered for re-sale, transferred or sub-licensed in whole or in part except that you may make one copy for archive purposes only.
                                    </p>

                                    <h5 className="card-title">3. USER AGREEMENT</h5>
                                    <h5 className="card-title">3.1 Use</h5>
                                    <p className="text-muted">
                                        Your license to use lil Triangle is limited to the number of licenses purchased by you. You shall not allow others to use, copy or evaluate copies of lil Triangle.
                                    </p>
                                    <h5 className="card-title">3.2 Use Restrictions</h5>
                                    <p className="text-muted">
                                        You shall use lil Triangle in compliance with all applicable laws and not for any unlawful purpose.
                                    </p>
                                    <h5 className="card-title">3.3 Copyright Restriction</h5>
                                    <p className="text-muted">This Software contains copyrighted material, trade secrets and other proprietary material. You shall not, and shall not attempt to, modify, reverse engineer, disassemble or decompile lil Triangle <b>[APP VERSION]</b>. Nor can you create any derivative works or other works that are based upon or derived from lil Triangle in whole or in part.</p>
                                    <p className="text-muted">LittleTriangle Pvt.Ltd's name, logo and graphics file that represents lil Triangle shall not be used in any way to promote products developed with lil Triangle. LittleTriangle Pvt.Ltd retains sole and exclusive ownership of all right, title and interest in and to lil Triangle and all Intellectual Property rights relating thereto.</p>
                                    <p className="text-muted">Copyright law and international copyright treaty provisions protect all parts of lil Triangle, products and services. No program, code, part, image, audio sample, or text may be copied or used in any way by the user except as intended within the bounds of the single user program. All rights not expressly granted hereunder are reserved for Oyoo Design Studio.</p>

                                    <h5 className="card-title">3.4 Limitation of Responsibility</h5>
                                    <p className="text-muted">You will indemnify, hold harmless, and defend LittleTriangle Pvt.Ltd, its employees, agents and distributors against any and all claims, proceedings, demand and costs resulting from or in any way connected with your use of LittleTriangle Pvt.Ltd's Software.</p>
                                    <p className="text-muted">In no event (including, without limitation, in the event of negligence) will LittleTriangle Pvt.Ltd , its employees, agents or distributors be liable for any consequential, incidental, indirect, special or punitive damages whatsoever (including, without limitation, damages for loss of profits, loss of use, business interruption, loss of information or data, or pecuniary loss), in connection with or arising out of or related to this Agreement, lil Triangle or the use or inability to use lil Triangle or the furnishing, performance or use of any other matters hereunder whether based upon contract, tort or any other theory including negligence.</p>

                                    <h5 className="card-title">3.5 Warranties</h5>
                                    <p className="text-muted">THIS SOFTWARE AND THE ACCOMPANYING FILES ARE SOLD "AS IS" AND WITHOUT WARRANTIES AS TO PERFORMANCE OR MERCHANTABILITY OR ANY OTHER WARRANTIES WHETHER EXPRESSED OR IMPLIED. THIS DISCLAIMER CONCERNS ALL FILES GENERATED AND EDITED BY lil Triangle AS WELL.</p>
                                    <h5 className="card-title">3.6 Governing Law</h5>
                                    <p className="text-muted">This Agreement shall be governed by the law of the <b>Indian Government</b> applicable therein.
                                        You hereby irrevocably attorn and submit to the non-exclusive jurisdiction of the courts of <b>Indian Government</b> therefrom. If any provision
                                        shall be considered unlawful, void or otherwise unenforceable, then that provision shall be deemed severable from this License and not affect the
                                        validity and enforceability of any other provisions.
                                    </p>

                                    <h5 className="card-title">3.7 Termination</h5>
                                    <p className="text-muted">
                                        Any failure to comply with the terms and conditions of this Agreement will result in automatic and immediate termination of this license. Upon termination of this license granted herein for any reason, you agree to immediately cease use of lil Triangle and destroy all copies of lil Triangle supplied under this Agreement.
                                    </p>
                                    <h5 className="card-title">3.8  CONSENT OF DATA SECURITY AND USE OF DATA</h5>
                                    <p className="text-muted">
                                        lil Triangle supports data security of data generated using app. Data privacy is ensured and school specific data will not be shared to any third-party. You agree that LittleTriangle Pvt.Ltd may collect and use information gathered in any manner as part of the product support services provided to you, if any, related to lil Triangle. Oyoo Design Studio may also use this information to provide notices, reports to you which may be of use or interest to you.
                                    </p>
                                    <h5 className="card-title">3.9 Maintenance and Support</h5>
                                    <p className="text-muted">
                                        lil Triangle does provide minimal maintenance or support for it but not to the extent that any maintenance or support is required by applicable law. <b>lil Triangle assures support and updates from the date of purchase based on plan subscribed.</b>
                                    </p>

                                    {/* <ul className="list-unstyled feature-list text-muted">
                                                    <li>
                                                        <i>
                                                            <FeatherIcon
                                                                icon="arrow-right"
                                                                className="fea icon-sm me-2"
                                                            />
                                                        </i>
                                                        Digital Marketing Solutions for Tomorrow
                                                    </li>
                                                    <li>
                                                        <i>
                                                            <FeatherIcon
                                                                icon="arrow-right"
                                                                className="fea icon-sm me-2"
                                                            />
                                                        </i>
                                                        Our Talented & Experienced Marketing Agency
                                                    </li>
                                                    <li>
                                                        <i>
                                                            <FeatherIcon
                                                                icon="arrow-right"
                                                                className="fea icon-sm me-2"
                                                            />
                                                        </i>
                                                        Create your own skin to match your brand
                                                    </li>
                                                    <li>
                                                        <i>
                                                            <FeatherIcon
                                                                icon="arrow-right"
                                                                className="fea icon-sm me-2"
                                                            />
                                                        </i>
                                                        Digital Marketing Solutions for Tomorrow
                                                    </li>
                                                    <li>
                                                        <i>
                                                            <FeatherIcon
                                                                icon="arrow-right"
                                                                className="fea icon-sm me-2"
                                                            />
                                                        </i>
                                                        Our Talented & Experienced Marketing Agency
                                                    </li>
                                                    <li>
                                                        <i>
                                                            <FeatherIcon
                                                                icon="arrow-right"
                                                                className="fea icon-sm me-2"
                                                            />
                                                        </i>
                                                        Create your own skin to match your brand
                                                    </li>
                                                </ul> */}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default TermsOfUse
