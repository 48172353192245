import React, { Component } from "react"
import { Container, Row, Col, Form, Button } from "reactstrap"

//Import Image
import Expense from "../../../assets/images/LilTriangleImages/Expense Management.webp"




class Section extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <section
          className="bg-half-170 d-table w-100 home-wrapper overflow-hidden"
          id="home"
        >
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={6} md={6}>
                <div className="title-heading">
                  <h2 className=" my-3">Expenses Management</h2>
                  <p className="para-desc text-muted mb-0">Streamline, Simplify and Succeed: Your Preschool and Daycare Effortlessly Managed with LilTriangle.</p>
                </div>
              </Col>

              <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4">
                  <img src={Expense} className="img-fluid" alt="" />
                </div>
              </Col>
              <div
                className="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
              </div>
            </Row>
          </Container>
        </section> */}
        <section
          className="p-5 bg-half-170 d-table w-100 home-wrapper "
          id="home"
        >
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={6} md={6}>
                <div className="title-heading">
                  <h2 className=" my-3">Expenses Management</h2>
                  <p className="para-desc text-muted mb-0">Effortlessly manage and record day-to-day expenses, ensuring transparency and financial efficiency</p>
                </div>
              </Col>

              <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4">
                  <img src={Expense} className="img-fluid" alt="" />
                </div>
              </Col>
              <div
                className="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default Section






