import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Container, Button } from "reactstrap"
import ScrollspyNav from "./scrollSpy"

//Import Images

import RightSidebar from '../../components/Layout/RightSidebar'

//React Drawer
import ReactDrawer from 'react-drawer'
import 'react-drawer/lib/react-drawer.css'

//Import Icons
import FeatherIcon from "feather-icons-react"
import LilTriangleLogWithName from "../../assets/images/LilTriangleImages/logoWithName.webp"

class NavbarPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "features", navheading: "Feature" },
        { id: 3, idnm: "testimonial", navheading: "Review" },
        // { id: 4, idnm: "pricing", navheading: "Price" },
        { id: 6, idnm: "aboutUs", navheading: "About Us" },
        { id: 7, idnm: "contact", navheading: "Contact" },
        { id: 8, idnm: "jobs", navheading: "Jobs" },
      ],
      isOpen: false,
      open: false,
      position: 'right',
    }
    this.toggleLine = this.toggleLine.bind(this)
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this)
    this.onDrawerClose = this.onDrawerClose.bind(this)
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }

  toggleRightDrawer() {
    this.setState({ position: 'right' })
    this.setState({ open: !this.state.open })
  }

  onDrawerClose() {
    this.setState({ open: false })
  }

  initMenu() {
    this.activateMenu()
  }

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = this.state.navItems.map((item) => {
      return item.idnm
    })

    return (
      <React.Fragment>
        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              <Link className="logo" to="/">
                <img src={LilTriangleLogWithName} height="30" alt="" />
              </Link>
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            <ul className="buy-button list-inline mb-0">
              <li className="list-inline-item mb-0 me-1 settingbtn">
                <Link to="#" onClick={() => {
                  window.open("https://calendly.com/liltriangle/liltriangle", "_blank")
                }} >
                  {/* <div className="d-grid">
                    <input
                      type="submit"
                      id="submitsubscribe"
                      name="send"
                      className={"btn btn-soft-primary"}
                      value="Book Demo" />
                  </div> */}
                  <Button
                    className="btn btn-soft-primary"
                    id="submitsubscribe"
                    name="send"
                  >
                    Book Demo
                  </Button>
                </Link>
              </li>
            </ul>

            <ScrollspyNav
              scrollTargetIds={targetId}
              scrollDuration="800"
              headerBackground="false"
              activeNavClass="active"
            >
              <div
                id="navigation"
                style={{ display: this.state.isOpen ? "block" : "none" }}
              >
                <ul className="navigation-menu">
                  {this.state.navItems.map((item, key) => (
                    <li
                      key={key}
                      className={
                        item.navheading === "Home"
                          ? "has-submenu active"
                          : "has-submenu"
                      }
                    >
                      {item.idnm === "jobs" ? (
                        <a
                          href="#"
                          onClick={() => {
                            window.open("/liltriangle-jobs/", "_blank")
                          }}
                          rel="noreferrer"
                        >
                          {item.navheading}
                        </a>
                      ) : (
                        <a href={"#" + item.idnm}> {item.navheading}</a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </ScrollspyNav>
          </Container>
        </header>
        <ReactDrawer
          open={this.state.open}
          position={this.state.position}
          onClose={this.onDrawerClose}
        >
          <RightSidebar onClose={this.onDrawerClose} />
        </ReactDrawer>
      </React.Fragment>
    )
  }
}

export default NavbarPage
