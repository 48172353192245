import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import AOS from "aos"
import "../../../../node_modules/aos/dist/aos.css"
import MainPage from "./MainPage"
import Popup from "../../../components/Layout/popup"
import NavbarPage from "./Navbar"
import Footr from "../Footer"
import Benefit from "./Benefits"
import Details from "./FeatureDetails"
import FeatureSlide from '../FeatureSlider'

const LilTriangle = () => {

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    // Initialize AOS Animation
    AOS.init()

    // Refresh AOS Animation whenever components will render
    AOS.refresh()

    const handleScroll = () => {
      var doc = document.documentElement
      const navBar = document.getElementById("topnav")
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      if (navBar != null) {
        if (top > 80) {
          navBar.classList.add("nav-sticky")
        } else {
          navBar.classList.remove("nav-sticky")
        }
      }
    }

    window.addEventListener("scroll", handleScroll, true)

    return () => {
      window.removeEventListener("scroll", handleScroll, true)
    }
  }, [])

  return (
    <React.Fragment>
      <NavbarPage />
      {/* <section className="section pb-0" id="home"> */}
        <MainPage />
      {/* </section> */}
      <section className="section pb-0" id="benefits">
        <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px' } : {}}>
          <Benefit />
        </div>
      </section>
      <section className="section pb-0" id="features">
        <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px' } : {}}>
          <Details />
        </div>
      </section>
      <section className=" pb-0" id="contact">
        <FeatureSlide/>
        <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px', background: '#202942' } : {}}>
          <Footr />
        </div>
      </section>
      {/* Popup */}
      {/* <Popup /> */}
    </React.Fragment>
  )
}

export default LilTriangle
