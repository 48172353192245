import React, { Component } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link, useHistory } from 'react-router-dom'

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle"
import Pricing from "../../components/Shared/PricingBox"

class Price extends Component {
  constructor(props) {
    super(props)
    this.state = {
      servicesData: [
        {
          id: 1,
          icon: "uil-clipboard-notes",
          title: "Enquiry Management",
          description: "Powerhouse for effortlessly recording forms, seamless follow-ups, and unlocking the insights you need with precision analytics",
          link: "/liltriangle-enquiry"
        },
        {
          id: 2,
          icon: "uil-comment-alt-message",
          title: "Communication",
          description: "School and Parents Connect Seamlessly, Ensuring Real-Time Updates.",
          link: "/liltriangle-communication"
        },
        {
          id: 3,
          icon: "uil-user-plus",
          title: "Attendance",
          description: "Seamlessly digitize student and teacher attendance with just a tap.",
          link: "/liltriangle-attendance"
        },
        {
          id: 4,
          icon: "uil-kid",
          title: "Daycare Management",
          description: "Every smile, snack, and nap is just a click away, saving parents from endless calls and keeping everyone connected with ease.",
          link: "/liltriangle-daycare"
        },
        {
          id: 5,
          icon: "uil-book-open",
          title: "LMS",
          description: "Every Achievement Leaves a Digital Footprint, Ensuring every Child's academic milestone is Tracked.",
          link: "/liltriangle-lms"
        },
        {
          id: 6,
          icon: "uil-credit-card",
          title: "Fee Management",
          description: "Seamlessly Generate Invoices, Effortlessly Record Payments, and Experience the Ease of Automated Digital Receipts",
          link: "/liltriangle-payment"
        },
        {
          id: 7,
          icon: "uil-webcam",
          title: "CCTV",
          description: "CCTV streaming feature ensures parents have a watchful eye on their child's journey, offering live streaming with top-notch security",
          link: "/liltriangle-cctv"
        },
        {
          id: 8,
          icon: "uil-chart-line",
          title: "Expenses Management",
          description: "Effortlessly manage and record day-to-day expenses, ensuring transparency and financial efficiency.",
          link: "/liltriangle-expense"
        },
        {
          id: 9,
          icon: "uil-map-marker-alt",
          title: "Transport Tracking",
          description: "Streamline Your School Bus Routes Where Transport Management Meets Effortless Navigation for Safe and Efficient Journeys!",
          link: "/liltriangle-transport"
        },
      ],
    }
  }




  render() {

    const defaultStyle = {
      color: "#3c4858", 
      transition: "color 0.3s", 
    }
    
    const businessFeatures = [
      {
        id: 1,
        icon: 'uil uil-search-alt h2 text-primary',
        title: "Search Engine Optimization",
        description:
          "Identifying relevant keywords to optimize website content for search engines.",
      },
      {
        id: 2,
        icon: 'uil uil-channel h2 text-primary',
        title: "Social Media Management:",
        description:
          "Develop a comprehensive social media strategy tailored to the preschool and daycare",
      },
      {
        id: 3,
        icon: 'uil uil-arrow-growth h2 text-primary',
        title: "Lead Generation",
        description:
          "A Well-Executed Social Media Campaign: Elevating Your Business with High-Quality Leads",
      },
      {
        id: 3.1,
        icon: 'uil uil-snowflake-alt h2 text-primary',
        title: "Creatives and Graphic Design",
        description:
          "Create visually appealing graphics, banners, and infographics for social media and website use.",
      },
      {
        id: 4,
        icon: 'uil uil-edit-alt h2 text-primary',
        title: "Content Writing",
        description:
          "Develop a content plan aligning with the preschool objectives ",
      },
      {
        id: 5,
        icon: 'uil uil-apps h2 text-primary',
        title: "Website Design and Development",
        description:
          "Enhancing Website Accessibility and Usability for a Varied Audience on Different Devices and Screen Sizes.",
      },
    ]
    return (
      <React.Fragment>
        <section className="section" id="pricing">
          <Container className="">
            <Row className="justify-content-center">
              <Col className="col-12 text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-4">Explore Our Services</h4>
                  <p className="text-muted para-desc mb-0 mx-auto">lil Triangle offers a cutting-edge solutions that provides a distinct advantage in monitoring and managing every operation at your preschool and daycare.</p>
                </div>
              </Col>
            </Row>
            <Row className="">
              {this.state.servicesData.map((item, key) => (
                <Col key={key} lg={4} md={6} className="mt-4 pt-2">
                  <a href={item.link} className="read-more">
                    <Card href={item.link} className="features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow ">
                      <div className="icons text-center mx-auto">
                        <i href={item.link} className={"uil d-block rounded h3 mb-0 mx-auto " + item.icon}></i>
                      </div>
                      <CardBody href={item.link} className="card-body p-0 content">
                        <h5 className="mt-4 text-center">
                          <a href={item.link} style={defaultStyle} onMouseEnter={(e) => e.target.style.color = "blue"} onMouseLeave={(e) => e.target.style.color = "black"}>{item.title}</a>
                        </h5>
                        <p className="text-muted text-center"
                        >{item.description}</p>
                        <p className="text-center"><a href={item.link} className="read-more">Read More <i className="uil uil-angle-right-b align-middle"></i></a></p>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <section className="section pt-5">
          <Container>
            {/* section title */}
            <Col className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4">Digital Marketing</h4>
                <p className="text-muted para-desc mx-auto mb-0">Supercharge Your Brand: Our Digital Marketing Services - Where Strategy Meets Impact, Elevating Your Online Presence Beyond Expectations.</p>
              </div>
            </Col>
            {/* feature box */}
            <Row className="text-center">
              {businessFeatures.map((item, key) => (
                <Col key={key} md={4} className="col-12 mt-5">
                  <div className="features feature-primary text-center m-5">
                    <div className="image position-relative d-inline-block">
                      <i className={item.icon}></i>
                    </div>

                    <div className="content mt-4">
                      <h5>{item.title}</h5>
                      <p className="text-muted mb-0">{item.description}</p>
                    </div>
                  </div>

                  {/* <Card className="features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                   
                      <CardBody className="card-body p-0 content">
                      <div className="features">
                      <div className="image position-relative d-inline-block">
                        <i className={"uil d-block rounded h3 mb-0 mx-auto " + item.icon}></i>
                      </div>
                        <div className="content mt-4">
                          <h5>{item.title}</h5>
                          <p className="text-muted mb-0">{item.description}</p>
                        </div>
                        </div>
                      </CardBody>
                    
                  </Card> */}

                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default Price
