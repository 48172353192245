import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

// IMport Images
import lilLogo from "../../assets/images/LilTriangleImages/lilLogo.webp"

// Modal Video
import ModalVideo from "react-modal-video"
import "../../../node_modules/react-modal-video/scss/modal-video.scss"
import { Col, Container, Row, Card, CardBody, } from "reactstrap"
import SectionTitle from "../../components/Shared/SectionTitle"

const AboutUs = () => {
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768)
        }

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const textToShow = showMore
        ? "Founded in the year 2017, lil Triangle is a pioneering preschool and daycare app that has revolutionized the way educators, parents, and caregivers communicate and engage in the early education journey. With an unwavering commitment to enhancing the preschool experience, lil Triangle has emerged as a trailblazer in providing an all-in-one platform for seamless communication, real-time updates, and comprehensive management tools. At lil Triangle, we understand the importance of effective communication and collaboration between preschools and parents. Our app offers a comprehensive suite of features designed to bridge the gap and create a harmonious partnership between schools and parents. From attendance tracking to sharing photos and videos of memorable moments, from distributing homework assignments to facilitating online fee payments – lil Triangle ensures that parents are always well-informed and engaged in their child's educational voyage."
        : "Founded in the year 2017, lil Triangle is a pioneering preschool and daycare app that has revolutionized the way educators, parents, and caregivers communicate and engage in the early education journey. With an unwavering commitment to enhancing the preschool experience, lil Triangle has emerged as a trailblazer in providing an all-in-one platform for seamless communication, real-time updates, and comprehensive management tools."

    const pricings = [
        {
            id: 1,
            title: "Free",
            price: 7000,
            duration: "mo",
            buttonText: "Buy Now",
            btnLink: "",
            features: [{ title: "Full Access" },
            ],
        },
        {
            id: 2,
            title: "Starter",
            price: 10000,
            duration: "mo",
            buttonText: "Get Started",
            btnLink: "",
            isActive: true,
            features: [
                { title: "Full Access" },
            ],
        },
        {
            id: 3,
            title: "PROFESSIONAL",
            price: 20000,
            duration: "mo",
            buttonText: "Try It Now",
            btnLink: "",
            features: [
                { title: "Full Access" },
            ],
        },
        {
            id: 4,
            title: "ULTIMATE",
            price: 49990,
            duration: "mo",
            buttonText: "Started Now",
            btnLink: "",
            features: [
                { title: "Full Access" },
            ],
        }
    ]

    const business = [
        {
            id: 1,
            icon: "uil-money-stack",
            title: "Directors and Administrators",
            description: "Empowering directors and administrators with a fingertip view of everything unfolding in schools",
            link: "/liltriangle-payment"
        },
        {
            id: 2,
            icon: "uil-money-stack",
            title: "Teachers and Staff",
            description: "Empowering teachers with seamless visibility and control over all aspects of their classrooms",
            link: "/liltriangle-communication"
        },
        {
            id: 3,
            icon: "uil-money-stack",
            title: "Parents and Guardians",
            description: "Seamlessly designed for parents to track their child's updates in real time",
            link: "/liltriangle-communication"
        },
    ]

    return (
        <React.Fragment>
            {!isMobileView && (<section className="section pt-5 desktop-view">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={5} md={6}>
                            <div className="position-relative">
                                <img
                                    src={lilLogo}
                                    className="rounded img-fluid mx-auto d-block shadow rounded"
                                    alt=""
                                />
                                <div className="play-icon">
                                    <Link
                                        to="#"
                                        onClick={() => setOpen(true)}
                                        className="play-btn video-play-icon"
                                    >
                                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                    </Link>
                                </div>
                            </div>
                        </Col>

                        <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            {/* <div className="section-title ms-lg-5">
                            <span className="badge rounded-pill bg-soft-primary">About us</span>
                            <h4 className="title mt-3 mb-4">What early education experts are saying about lil Triangle</h4>
                            <p className="text-muted para-desc">Founded in the year 2017, lil Triangle is a pioneering preschool and daycare app that has revolutionized the way educators, parents, and caregivers communicate and engage in the early education journey. With an unwavering commitment to enhancing the preschool experience, lil Triangle has emerged as a trailblazer in providing an all-in-one platform for seamless communication, real-time updates, and comprehensive management tools. At lil Triangle, we understand the importance of effective communication and collaboration between preschools and parents. Our app offers a comprehensive suite of features designed to bridge the gap and create a harmonious partnership between schools and parents. From attendance tracking to sharing photos and videos of memorable moments, from distributing homework assignments to facilitating online fee payments – lil Triangle ensures that parents are always well-informed and engaged in their child's educational voyage.</p>
                            <p className="text-muted para-desc mb-0"></p>
                        </div> */}
                            <div className="section-title ms-lg-5">
                                <span className="badge rounded-pill bg-soft-primary">About us</span>
                                <h4 className="title mt-3 mb-4">
                                    About lil Triangle
                                </h4>
                                <p className="text-muted para-desc">
                                    {textToShow}
                                </p>
                                {!showMore && (
                                    <Link to="#" onClick={() => { setShowMore(!showMore) }} className="read-more">Read More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>)}
            {isMobileView && (<section className="section pt-5 mobile-view">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            {/* <div className="section-title ms-lg-5">
                            <span className="badge rounded-pill bg-soft-primary">About us</span>
                            <h4 className="title mt-3 mb-4">What early education experts are saying about lil Triangle</h4>
                            <p className="text-muted para-desc">Founded in the year 2017, lil Triangle is a pioneering preschool and daycare app that has revolutionized the way educators, parents, and caregivers communicate and engage in the early education journey. With an unwavering commitment to enhancing the preschool experience, lil Triangle has emerged as a trailblazer in providing an all-in-one platform for seamless communication, real-time updates, and comprehensive management tools. At lil Triangle, we understand the importance of effective communication and collaboration between preschools and parents. Our app offers a comprehensive suite of features designed to bridge the gap and create a harmonious partnership between schools and parents. From attendance tracking to sharing photos and videos of memorable moments, from distributing homework assignments to facilitating online fee payments – lil Triangle ensures that parents are always well-informed and engaged in their child's educational voyage.</p>
                            <p className="text-muted para-desc mb-0"></p>
                        </div> */}
                            <div className="section-title ms-lg-5">
                                <span className="badge rounded-pill bg-soft-primary">About us</span>
                                <h4 className="title mt-3 mb-4">
                                    About lil Triangle
                                </h4>
                                <p className="text-muted para-desc">
                                    {textToShow}
                                </p>
                                {!showMore && (
                                    <Link to="#" onClick={() => { setShowMore(!showMore) }} className="read-more">Read More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                )}
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className="position-relative">
                                <img
                                    src={lilLogo}
                                    className="rounded img-fluid mx-auto d-block shadow rounded"
                                    alt=""
                                />
                                <div className="play-icon">
                                    <Link
                                        to="#"
                                        onClick={() => setOpen(true)}
                                        className="play-btn video-play-icon"
                                    >
                                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>)}
            <ModalVideo
                channel="youtube"
                isOpen={open}
                videoId="UISBDll-l0k"
                onClose={() => setOpen(false)}
            />
            <ModalVideo
                channel="youtube"
                isOpen={open1}
                videoId="2VdXHcO2NWA"
                onClose={() => setOpen1(false)}
            />
            <ModalVideo
                channel="youtube"
                isOpen={open2}
                videoId="GXyhQE8tZUc"
                onClose={() => setOpen2(false)}
            />
            <ModalVideo
                channel="youtube"
                isOpen={open3}
                videoId="iudAQheUS00"
                onClose={() => setOpen3(false)}
            />
            <section className="pt-5">
                <Container className="mt-100 mt-60">
                    <SectionTitle title="Designed with you in mind" />
                    <div className="rounded bg-primary bg-gradient p-lg-5 p-4">
                        <Row>
                            {business.map((item, key) => (
                                <Col key={key} className="mt-4 pt-2">
                                    <Card className="features feature-primary  explore-feature p-4   text-center shadow">
                                        <div className="icons rounded-circle  text-center mx-auto bg-info">
                                            <i className={"h3 " + item.icon}></i>
                                        </div>

                                        <CardBody className="card-body p-0 ">
                                            <h5 className="mt-4"><Link to="#" className="title text-dark">{item.title}</Link></h5>
                                            <p className="text-muted">{item.description}</p>

                                            {/* <Link to={item.link} target="_blank" className="read-more">Learn More <i className="uil uil-angle-right-b align-middle"></i></Link> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </section>

        </React.Fragment>
    )
}
export default AboutUs