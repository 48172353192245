import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"
import FeatureSlider from '../FeatureSlider'

// import Images
import amico from '../../../assets/images/LilTriangleImages/Daycare feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Infant Updates
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                The app allows daycare staff to input and send updates to parents regarding their infants. These updates may include food updates, Daiper changes, nap timings and more
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Child Observation
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                A feature for daycare staff to record and share observations about infants' activities, milestones, and behavior
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Medication Records
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                Allows daycare staff to input information about any medications administered to infants.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
