import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"
import FeatureSlider from '../FeatureSlider'

// import Images
import amico from '../../../assets/images/LilTriangleImages/tracking feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Live Location Tracking
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Parents can track the live location of the Preschool bus in real-time through the lil Triangle app. This feature provides a map interface displaying the exact location of the bus,
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  No More Waiting
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Parents no longer need to wait at the bus stop unsure of when the bus will arrive
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Misuse Prevention
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Preschools can monitor the bus movements closely, ensuring that the buses follow the designated routes
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Historical Data and Analytics
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  The app records historical data related to bus routes, timings, and stops. Preschools can analyze this data to make informed decisions, optimize routes, and enhance the overall efficiency of the transportation system.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
