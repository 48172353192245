import React, { Component } from "react"
import { Container, Row, Col, Form, Button } from "reactstrap"
import Tracking from "../../../assets/images/LilTriangleImages/Tracking.webp"


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="p-5 bg-half-170 d-table w-100 home-wrapper "
          id="home"
        >
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={6} >
                <div className="title-heading">
                  <h2 className=" my-3">Transport: lilTrips App</h2>
                  <p className="para-desc text-muted mb-0">Streamline Your School Bus Routes Where Transport Management Meets Effortless Navigation for Safe and Efficient Journeys!</p>
                  {/* <p className="para-desc text-muted mb-0">The lilTrips feature offered by lilTriangle is a comprehensive tool designed to assist schools in monitoring and overseeing school buses. It provides real-time notifications for pick-ups and drop-offs, along with live GPS tracking functionality.</p> */}
                </div>
              </Col>

              <Col lg={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4 d-flex justify-content-center">
                  <img src={Tracking} className="img-fluid" width={300} alt="" />
                </div>
              </Col>
              <div
                className="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
              </div>
            </Row>
          </Container>
        </section>

      </React.Fragment>
    )
  }
}

export default Section






