import React, { Component } from "react"
import { Container, Row, Col, Form, Button } from "reactstrap"
import Image from "../../../assets/images/modern01.jpg"

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="bg-half-100 d-table w-100 home-wrapper overflow-hidden"
          id="home"
        >
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={6} md={6}>
                <div className="title-heading">
                  <h2 className=" my-3">Clients And Customers</h2>
                  <p className="para-desc text-muted mb-0">Streamline, Simplify and Succeed: Your Preschool and Daycare Effortlessly Managed with lil Triangle.</p>
                </div>
              </Col>

              <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4">
                  {/* <img src={homebg} className="img-fluid" alt="" /> */}
                  <img src={Image} className="img-fluid" alt="" />
                </div>
              </Col>
              <div
                className="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
              </div>
            </Row>
          </Container>
        </section>

      </React.Fragment>
    )
  }
}

export default Section






