import React from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader, Collapse } from "reactstrap"
import FeatherIcon from "feather-icons-react"
import { Link } from "react-router-dom"

function PrivacyPolicy() {
    return (
        <React.Fragment>
            <section className="bg-half-100 bg-light d-table w-100">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} className="text-center">
                            <div className="pages-heading">
                                <h4 className="title"> REFUND POLICY </h4>
                            </div>
                        </Col>
                    </Row>
                    <div className="position-breadcrumb">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                        <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>{" "}
                            <li className="breadcrumb-item active" aria-current="page">Refund Policy</li>
                        </ul>
                    </nav>
                </div>
                </Container>
            </section>

            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={9}>
                            <Card className="shadow border-0 rounded">
                                <CardBody>
                                    <h5 className="card-title">Refund Policy</h5>
                                    <p className="text-muted">
                                        We will refund your amount, but if someone tries to misuse, they’re legally bound.
                                    </p>
                                    <p className="text-muted">
                                        Account closing
                                    </p>
                                    <ul className="list-unstyled feature-list text-muted">
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            The user may close his account at any time. Account closing means that the further use of services with the use of current password and login shall no longer be possible.
                                        </li>
                                        <li>
                                            <li>
                                                <i>
                                                    <FeatherIcon
                                                        icon="arrow-right"
                                                        className="fea icon-sm me-2"
                                                    />
                                                </i>
                                                The user is responsible for closing his account.
                                            </li>
                                        </li>
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            Closing the account by the user without using lil Triangle services for a long time does not obligate lil Triangle to refund the amount for the unused period.
                                        </li>
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            lil Triangle reserves the right to close user’s account due to gross infringement of the provisions of these terms and conditions or in the event of illegal use of the offered services.
                                        </li>
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            lil Triangle shall not be liable for damage suffered by the user arisen due to the suspension or closing the account by the user or for other reasons arising from the user’s fault.
                                        </li>
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            There will be no refunds after 6 months of purchase under any circumstances.                                        </li>
                                    </ul>
                                    <p className="text-muted">
                                        Guarantee and limited liability.
                                    </p>
                                    <ul className="list-unstyled feature-list text-muted">
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            lil Triangle guarantees the highest quality of its operations to ensure accessibility and continuity of offered services and products in accordance with their use and purpose. No long distance data transmission, however, guarantees 100% security, continuity and accessibility of the provided services.
                                        </li>
                                        <li>
                                            <li>
                                                <i>
                                                    <FeatherIcon
                                                        icon="arrow-right"
                                                        className="fea icon-sm me-2"
                                                    />
                                                </i>
                                                Although lil Triangle tries to, but does not guarantee compatibility of offered services and products with other producers’ software. The user shall bear responsibility for the choice and consequences following from the use of other software including its applicability to user’s objectives.
                                            </li>
                                        </li>
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            lil Triangle stipulates that opinions given by users do not reflect in any possible manner its views and opinions. lil Triangle does not monitor or control users’ opinions on a continual basis if, however, any opinions in breach with these terms and conditions should be revealed, efforts shall be immediately made to remove them as soon as possible.
                                        </li>
                                        <li>
                                            <i>
                                                <FeatherIcon
                                                    icon="arrow-right"
                                                    className="fea icon-sm me-2"
                                                />
                                            </i>
                                            lil Triangle shall bear no liability in particular for:
                                        </li>
                                    </ul>
                                    <ul className="text-muted">
                                        <li>
                                            Phrases and entries added to the network in connection with the use of offered services and products,
                                        </li>
                                        <li>
                                            Unlawful and inconsistent with these terms and conditions users’ operations while using offered services and products,
                                        </li>
                                        <li>
                                            Disturbances in accessibility of offered products and services not caused by lil Triangle,
                                        </li>
                                        <li>
                                            Damage suffered by the user arisen due to the suspension or closing the account by the user or for other reasons arising from the user’s fault,
                                        </li>
                                        <li>
                                            Damage suffered by the user as a result of a third party using his data that enable him to access the provided services and products,
                                        </li>
                                        <li>
                                            Damage following from the use or the impossibility to use offered products and services including damage actually suffered, the loss of expected benefits, data loss, damage or computer crash, the costs of substitute equipment and software, shut-down, company reputation infringement,
                                        </li>
                                        <li>
                                            Contact Details (Permanent Address, Correspondence Address, Email ID, Mobile No etc.)
                                        </li>
                                        <li>
                                            Consequences of failure to perform or improper performance of obligations undertaken by other users even though such an obligation has been undertaken using offered products and services
                                        </li>
                                        <li>
                                            lil Triangle is not responsible for the damage suffered by the user as a result of a bot attack or phishing due to third-party.
                                        </li>
                                    </ul>


                                    <h5 className="card-title">Privacy Policy</h5>
                                    <p className="text-muted">
                                        All disputes arising in connection with these terms and conditions shall be primarily resolved amicably
                                    </p>

                                    <h5 className="card-title">Final provisions</h5>
                                    <p className="text-muted">
                                        All disputes that cannot be amicably resolved shall be submitted for resolution by Common Court of Law at the location of lil Triangle registered office.
                                    </p>
                                    <p className="text-muted">
                                        The provisions of Civil Code, the act on copyright and neighbouring rights, an act on electronic services providing and other provisions of effective law shall be applicable in issues not governed by these terms and conditions.
                                    </p>
                                    <p className="text-muted">
                                        Our terms are aligned and subject to modification according to laws and guidelines by local government bodies.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default PrivacyPolicy
