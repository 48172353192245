import React, { useEffect, useState } from "react"
import { Col, Container, Row, Media } from "reactstrap"
import Slider from "react-slick"
import { useLocation } from "react-router-dom"

// Import Slick Slider CSS
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Feature = () => {

    const location = useLocation()

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768)
        }

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const travel = [
        {
            id: 1,
            title: "Enquiry Management",
            link: "/liltriangle-enquiry",
            icon: "uil-clipboard-notes",
        },
        {
            id: 2,
            title: "Communication",
            link: "/liltriangle-communication",
            icon: "uil-comment-alt-message",
        },
        {
            id: 3,
            title: "Attendance",
            link: "/liltriangle-attendance",
            icon: "uil-user-plus",
        },
        {
            id: 4,
            title: "Daycare Management",
            link: "/liltriangle-daycare",
            icon: "uil-kid",
        },
        {
            id: 5,
            title: "LMS",
            link: "/liltriangle-lms",
            icon: "uil-book-open",
        },
        {
            id: 6,
            title: "Fee Management",
            link: "/liltriangle-payment",
            icon: "uil-credit-card",
        },
        {
            id: 7,
            title: "CCTV",
            link: "/liltriangle-cctv",
            icon: "uil-webcam",
        },
        {
            id: 5,
            title: "Expenses Management",
            link: "/liltriangle-expense",
            icon: "uil-chart-line",
        },
        {
            id: 9,
            title: "Transport Tracking",
            link: "/liltriangle-transport",
            icon: "uil-map-marker-alt",
        },
    ]

    const settings2 = {
        dots: false,
        infinite: true,
        autoplaySpeed: 1000,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    return (
        <React.Fragment>
            <section className="section p-1" style={{ marginTop: '80px' }}>
                <Container fluid>
                    <Row className="" style={{ backgroundColor: "#f8f9fa" }}>
                        <Col md={12} className="pt-3">
                            {/* <Slider {...settings2} className="owl-carousel owl-theme">
                                {travel.map((item, key) => (
                                    <div key={key} className="d-flex features feature-primary align-items-center p-2 mb-2  shadow" >
                                        <a href={item.link} className="w-100">
                                            <Row className="align-items-center">
                                                <Col lg={4} className="text-center">
                                                    <div className="icon rounded-circle">
                                                        <i href={item.link}
                                                            style={{ color: location.pathname === item.link ? "" : "black" }}
                                                            className={"uil d-block rounded h3 mb-0 mx-auto " + item.icon}>
                                                        </i>
                                                    </div>
                                                </Col>
                                                <Col lg={8}>
                                                    <Media body>
                                                        <h5 style={{ color: location.pathname === item.link ? "" : "black" }} className="title mb-0">
                                                            {item.title}
                                                        </h5>
                                                    </Media>
                                                </Col>
                                            </Row>
                                        </a>
                                    </div>
                                ))}
                            </Slider> */}
                            {!isMobileView ? <Slider {...settings2} className="owl-carousel owl-theme">
                                {travel.map((item, key) => (
                                    <div key={key}>
                                    <div  className="d-flex features feature-primary p-2 mb-2 rounded shadow mx-auto">
                                        <a href={item.link} className="w-100">
                                            <Row className="align-items-center">
                                                <Col lg={3} className="text-center">
                                                    <div className="icon rounded-circle">
                                                        <i
                                                            href={item.link}
                                                            style={{ color: location.pathname === item.link ? "" : "black" }}
                                                            className={"uil d-block rounded h4 mb-0 mx-auto " + item.icon}
                                                        ></i>
                                                    </div>
                                                </Col>
                                                <Col lg={9}>
                                                    <Media body>
                                                        <p style={{ color: location.pathname === item.link ? "" : "black" }} className="mb-0">
                                                            {item.title}
                                                        </p>
                                                    </Media>
                                                </Col>
                                            </Row>
                                        </a>
                                    </div>
                                    </div>
                                ))}
                            </Slider>
                            :
                            <Slider {...settings2} className="owl-carousel owl-theme">
                                {travel.map((item, key) => (
                                    <div key={key} className="d-flex feature-primary p-2 mb-2 rounded shadow mx-auto">
                                        <a href={item.link} className="w-100">
                                            <Row className="align-items-center">
                                                <Col lg={4} className="text-center">
                                                    <div className="icon rounded-circle">
                                                        <i
                                                            href={item.link}
                                                            style={{ color: location.pathname === item.link ? "" : "black" }}
                                                            className={"uil d-block rounded h4 mb-0 mx-auto " + item.icon}
                                                        ></i>
                                                    </div>
                                                </Col>
                                                <Col lg={8}>
                                                    <Media body>
                                                        <p style={{ color: location.pathname === item.link ? "" : "black" }} className="text-center mb-0">
                                                            {item.title}
                                                        </p>
                                                    </Media>
                                                </Col>
                                            </Row>
                                        </a>
                                    </div>
                                ))}
                            </Slider>}
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Feature
