import React, { useEffect } from 'react'

export default function RedirectComponent() {

    useEffect(() => {
        let path = window.location.hash
        console.log(path)
        if (path && path.toLowerCase().includes('newenquiry')) {
            let spilts = path.split('/')
            window.location.replace('https://forms.liltriangle.com/enquiry-form/' + spilts[spilts.length - 1])
        } else {
            window.location.replace('https://dashboard.liltriangle.com')
        }
    }, [])

    return (
        <React.Fragment>
        </React.Fragment>
    )
}
