import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"
import FeatureSlider from '../FeatureSlider'

// import Images
import amico from '../../../assets/images/LilTriangleImages/expense feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Record Daily Expenses
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Users can easily record their day-to-day expenses, categorizing them for better organization.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Total Remittance Tracker
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Display the total amount remitted to the Preschool branch, providing a clear picture of financial transactions.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Receipt Upload Functionality
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Receipts are automatically linked to corresponding expenses for seamless tracking and auditing.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Expense Categories
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Comprehensive list of predefined expense categories tailored for Preschool expenses.Users can also add custom categories to accommodate specific or unique expenses                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Insightful Reports
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Generate detailed reports summarizing Preschool expenses over specific periods                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
