import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Label, Input, Alert } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import { matchPath } from "react-router"
import Navbar from './Navbar'
import Footer from '../Footer'
import Section from './Section'
import Parse from 'parse'
import Moment from 'moment'

const ApplyJobModal = (props) => {


    const [succeMsg, setsucceMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [resume, setResume] = useState()
    const [parseResume, setParseResume] = useState()

    const [resumeName, setResumeName] = useState(undefined)

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [resumeError, setResumeError] = useState(false)
    const [resumeSizeError, setResumeSizeError] = useState(false)
    const [resumeFileError, setResumeFileError] = useState(false)

    useEffect(() => {
        let id = localStorage.getItem('jobApplicationId')

        if (id) {
            var Enquiry = Parse.Object.extend("JobApplications")
            var query = new Parse.Query(Enquiry)
            query.fromLocalDatastore()
            query.equalTo('objectId', id)
            query.first().then(
                (formData) => {
                    console.log(formData)
                    if (formData) {
                        setName(formData.attributes.name)
                        setEmail(formData.attributes.emailId)
                        setPhone(formData.attributes.phoneNumber)
                        setParseResume(formData.attributes.resume)
                        let newName = formData.attributes.resume._name.split("_").slice(1).join("")
                        setResumeName(newName)
                    }
                }
            )
        }

    }, [])


    function handleSubmit() {
        let canSave = true

        if (!name) {
            setNameError(true)
            canSave = false
        }

        if (!email) {
            setEmailError(true)
            canSave = false
        }

        if (!phone) {
            setPhoneError(true)
            canSave = false
        }
        if (!resume) {
            setResumeError(true)
            canSave = false
        }

        if (!canSave) {
            return
        }

        const parseFile = parseResume ?? new Parse.File('resume.pdf', resume)


        const JobApplications = Parse.Object.extend("JobApplications")
        var jobApplication = new JobApplications()

        jobApplication.set("name", name)
        jobApplication.set("emailId", email)
        jobApplication.set("jobId", props.selectedJob.id && props.selectedJob.id)
        jobApplication.set("phoneNumber", phone)
        jobApplication.set("resume", parseFile)

        jobApplication.set("schoolId", props.selectedJob && props.selectedJob.attributes.schoolId)
        jobApplication.set("instituteId", props.selectedJob && props.selectedJob.attributes.instituteId)


        jobApplication.save().then(
            (result) => {
                result.pin()
                localStorage.setItem('jobApplicationId', result.id)
                setsucceMsg(true)
                console.log(result)
                setsucceMsg(false)

            },
            (error) => {
                console.log(error)
            }
        )

    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            console.log(file)

            if (file.size > 2 * 1024 * 1024) {
                setResume(null)
                setResumeSizeError(true)
                setResumeFileError(false)
            } else if (file.type !== 'application/pdf') {
                setResume(null)
                setResumeFileError(true)
                setResumeSizeError(false)
            } else {
                setParseResume(undefined)
                setResumeName(undefined)
                setResume(file)
                setResumeFileError(false)
                setResumeSizeError(false)
            }
        }
    }


    return (
        <React.Fragment>
            <Modal isOpen={props.applyJobPopup} size='md'>
                <ModalHeader
                    toggle={() => {
                        props.onClosePopUp()
                    }}>
                    Apply Job
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label className="form-label">
                                        Your Name :<span className="text-danger">*</span>
                                    </Label>
                                    <div className="form-icon position-relative">
                                        <i>
                                            <FeatherIcon
                                                icon="user"
                                                className="fea icon-sm icons"
                                            />
                                        </i>
                                        <Input
                                            name="name"
                                            id="name"
                                            value={name}
                                            type="text"
                                            className="form-control ps-5"
                                            required
                                            placeholder="First Name :"
                                            onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {nameError && <small className='text-danger'>*Enter Name</small>}
                            </Col>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label className="form-label">
                                        Your Email :<span className="text-danger">*</span>
                                    </Label>
                                    <div className="form-icon position-relative">
                                        <i>
                                            <FeatherIcon
                                                icon="mail"
                                                className="fea icon-sm icons"
                                            />
                                        </i>
                                        <Input
                                            name="email"
                                            id="email"
                                            value={email}
                                            type="email"
                                            className="form-control ps-5"
                                            required
                                            placeholder="Your email :"
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {emailError && <small className='text-danger'>*Enter Email Id</small>}
                            </Col>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label className="form-label">
                                        Your Phone no. :<span className="text-danger">*</span>
                                    </Label>
                                    <div className="form-icon position-relative">
                                        <i>
                                            <FeatherIcon
                                                icon="phone"
                                                className="fea icon-sm icons"
                                            />
                                        </i>
                                        <Input
                                            name="number"
                                            id="number"
                                            type="phone"
                                            value={phone}
                                            className="form-control ps-5"
                                            required
                                            placeholder="Your phone no. :"
                                            onChange={(e) => {
                                                setPhone(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {phoneError && <small className='text-danger'>*Enter Phone Number</small>}
                            </Col>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label className="form-label">Upload Your Cv / Resume :<span className="text-danger">*</span></Label>
                                    {parseResume && <div>
                                        <div className='d-flex justify-content-between border' style={{ borderWidth: '1px', borderColor: 'black', borderStyle: 'solid' }}>
                                            <p className='m-2'><span className='mdi mdi-file-pdf m-1'></span>{resumeName}</p>
                                            <a href={parseResume.url()} target="_blank" rel="noopener noreferrer" className='m-2' style={{ cursor: "pointer" }}><u>view</u></a>
                                        </div>

                                        <p>Or upload new</p>
                                    </div>}
                                    <Input
                                        type="file"
                                        className="form-control"
                                        required
                                        id="fileupload"
                                        accept=".pdf"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                {resumeFileError && <small className="text-danger">Please upload a PDF file</small>}
                                {resumeError && <small className='text-danger'>*Please Upload Resume</small>}
                                {resumeSizeError && <small className='text-danger'>*Resume size should not exceed 2MB</small>}
                            </Col>
                            <Col md="12">
                                <Alert
                                    color="info"
                                    isOpen={succeMsg}
                                    toggle={() => {
                                        setsucceMsg(false)
                                    }}
                                >
                                    Applied successfully
                                </Alert>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' className='btn m-1'
                        onClick={() => {
                            handleSubmit()
                        }}>
                        Apply Now
                    </Button>
                    <Button color='secondary' className='btn m-1'
                        onClick={() => {
                            props.onClosePopUp()
                        }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default ApplyJobModal
