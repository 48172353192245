import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"
import FeatureSlider from '../FeatureSlider'

// import Images
import amico from '../../../assets/images/LilTriangleImages/Enquiry Feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  QR Code-Enabled Form
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Say goodbye to tedious paperwork! With our innovative QR code scanning, parents can effortlessly fill out enquiry forms in a snap – making the enrollment process as easy as scan, click, and enroll
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Integrated Digital Forms
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Preschools can easily create and customize digital enquiry and admission forms tailored to their needs. Capture essential information from parents with ease.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Customer Management
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Track and manage all your enquiries effortlessly. lil Triangle centralizes all your leads, making it simple to organize and prioritize them.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Follow-up Reminders
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Convert leads into admission with timely follow-ups. lil Triangle lets you set follow-up reminders, ensuring that you stay engaged with your potential parents.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Lead Status Tracking:
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Know where each lead stands in your sales pipeline. lil Triangle allows you to track the status of every enquiry, from initial contact to final conversion.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
