import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"

// import Images
import amico from '../../../assets/images/LilTriangleImages/cctv featute.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Customizable Camera Access
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Preschools have complete control over camera access. They can decide which cameras are accessible to whom, providing flexibility and customization based on their needs.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Enhanced Security
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  lil Triangle restricts the ability to take screenshots or record the screen during streaming sessions, guaranteeing the security and privacy of the live video feed.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Secure and Private
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Streaming is only accessible when the child checks in at Preschool, and it automatically stops when the child checks out. Screenshots and screen recording are restricted, ensuring that the streaming content remains secure and cannot be captured or saved.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  No Additional Hardware Required
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  lil Triangle does not require any additional hardware to set up the streaming service.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Server-Based Streaming
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Streaming is powered by lil Triangle's server, reducing the load on the Preschools internet connection and DVR systems . This ensures a smooth and uninterrupted streaming experience without buffering
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
