import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import CountUp from "react-countup"

import { Link } from "react-router-dom"
// Modal Video
import ModalVideo from "react-modal-video"

//Import Containers
import SectionTitle from "../../components/Shared/SectionTitle"
import ReviewsSlider from "../../components/Shared/ReviewsSlider"
import Slider from "react-slick"

//Import Slick Slider CSS
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

//Import Images

import mask_img from "../../assets/images/LilTriangleImages/man.webp"

import HelloKidsLogo from '../../assets/images/LilTriangleImages/schoolLogos/helloKidsLogo.jpeg'
import LearningCurveLogo from '../../assets/images/LilTriangleImages/schoolLogos/theLearningCurveLogo.webp'
import ACME from '../../assets/images/LilTriangleImages/schoolLogos/ACME_schoolo_Logo.webp'
import Amelio from '../../assets/images/LilTriangleImages/schoolLogos/Amelio-Logo-.webp'
import Jumpstart from '../../assets/images/LilTriangleImages/schoolLogos/Jumpstart-Logo.webp'
import Kidscastle from '../../assets/images/LilTriangleImages/schoolLogos/Kids-castle-Logo.webp'
import OrangeLogo from '../../assets/images/LilTriangleImages/schoolLogos/OrangeLogo.webp'
import cubbytalesLogo from '../../assets/images/LilTriangleImages/schoolLogos/cubbytalesLogo.webp'
import garodia from '../../assets/images/LilTriangleImages/schoolLogos/garodia-academy-preschool-logo.webp'
import medhaamLogo from '../../assets/images/LilTriangleImages/schoolLogos/medhaamLogo.svg'
import sanfortLog from '../../assets/images/LilTriangleImages/schoolLogos/sanfortLog.webp'
import KareLogo from '../../assets/images/LilTriangleImages/schoolLogos/Kare_logo.webp'
import NewDehliSchool from "../../assets/images/LilTriangleImages/schoolLogos/new dehli international school logo.webp"



import CubbyTales from '../../assets/images/LilTriangleImages/SchoolImages/cubbyTails.webp'
import LearningCurve from '../../assets/images/LilTriangleImages/SchoolImages/Learing curve suhas1.webp'
import HelloKids from '../../assets/images/LilTriangleImages/SchoolImages/hello kids preetham.webp'

const Testimonials = () => {

  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const reviews = [
    {
      id: 1,
      img: mask_img,
      name: "Anil Raj",
      post: "Teacher",
      desc: "Simple and intuitive interface. What matters a lot is the ease of use and simple interface that makes it ease and quick to complete tasks",
      rating: 5,
    },

    {
      id: 2,
      img: mask_img,
      name: "jaspreet singh",
      post: "Director",
      desc: "Our experience with Lil triangle is amazing It covers all the important and minute requirements a pre school requires.Back end support is very prompt. Sachin Pal is the concerned person looking after us from the day one.He is very efficient and prompt always looking after us throughout our subscription We are very happy with the services.",
      rating: 5,
    },
    {
      id: 3,
      img: mask_img,
      name: "Viji Kumar",
      post: "Parent",
      desc: "Lil triangle app is very good I travelled to Australia, with the help of this app we are in touch with the school on assignment and the school day to day activities, without this app, this would not have been possible.",
      rating: 4.5,
    },
    {
      id: 4,
      img: mask_img,
      name: "Sayali Subnis",
      post: "Parent",
      desc: "This app gives me timely details about my baby's activities, be it her food, snacks, sleep time, the time she entered, the time she exited from the day care. This app keeps parents updated with the kids timely activities.",
      rating: 5,
    },
    {
      id: 5,
      img: mask_img,
      name: "Tejas panwar",
      post: "Parent",
      desc: "great app! It's so easy to use and understand, so helpful! so organized. I love it. This app helps a lot, providing you with videos, messages, and all the latest updates. You can send feedback as well. It has been a great experience for us. highly recommended.😊",
      rating: 5,
    },
    {
      id: 6,
      img: mask_img,
      name: "Pullaiah Maddumala",
      post: "Teacher",
      desc: "#Lil Triangle is good app to the parents and the school management and the class teacher on regular basis. It will help us to get daily updates like activities for the students and out daily basis things to send to school for our kids. A great initiative from the school management. A special thanks to the school management",
      rating: 5,
    },
  ]

  const travel = [
    {
      id: 1,
      image: garodia,
      title: "Garodia",
      cn: 'py-4'
    },
    {
      id: 2,
      image: LearningCurveLogo,
      title: "The Learning Curve",
      cn: 'py-4'
    },
    {
      id: 3,
      image: ACME,
      title: "ACME",
      cn: 'py-0'
    },
    {
      id: 4,
      image: Jumpstart,
      title: "Jumpstart",
      cn: 'py-4'
    },
    {
      id: 5,
      image: cubbytalesLogo,
      title: "cubbytales",
      cn: 'p-0'
    },

    {
      id: 6,
      image: Kidscastle,
      title: "Kidscastle",
      cn: 'py-4'
    },
    {
      id: 7,
      image: HelloKidsLogo,
      title: "Hello Kids",
      cn: 'py-2'
    },
    {
      id: 5,
      image: Amelio,
      title: "Amelio",
      cn: 'py-2'
    },

    {
      id: 9,
      image: sanfortLog,
      title: "sanfort",
      cn: 'py-3'
    },
    {
      id: 10,
      image: medhaamLogo,
      title: "medhaam",
      cn: 'py-5'
    },
    {
      id: 11,
      image: KareLogo,
      title: "Kare",
      cn: 'py-3'
    },
    {
      id: 12,
      image: NewDehliSchool,
      title: "New Dehli School",
      cn: 'py-4'
    },
    {
      id: 13,
      image: OrangeLogo,
      title: "Orange",
      cn: 'py-4'
    },
  ]

  const settings2 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const counters = [
    {
      title: "Preschools ",
      end: 2500,
      postFix: "+",
      desc: "Teachers and Parents Ecosysytems",
    },
    {
      title: "Experience",
      end: 7,
      postFix: "+",
      desc: "Built with passion and care ",
    },
    {
      title: "Students",
      end: 2,
      postFix: "Lakh+",
      desc: "4 lakh happy Parents and 25K Teachers",
    },
  ]

  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Our Testimonial"
        // desc="that can provide everything you need to generate awareness, drive traffic, connect."
        />

        {/* clients slider */}
        <ReviewsSlider reviews={reviews} />
      </Container>
      <section className="section pt-5">
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <div className="rounded shadow bg-white p-4">
              <Row id="counter">
                {counters.map((counter, key) => (
                  <Col md={4} key={key}>
                    <div className="counter-box text-center">
                      <h1 className="mb-0 mt-3">
                        <span className="counter-value">
                          <CountUp end={counter.end} duration={8} />
                        </span>
                        {counter.postFix}
                      </h1>
                      <h5 className="counter-head mb-1">{counter.title}</h5>
                      <p className="text-muted mb-0">{counter.desc}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div className="feature-posts-placeholder" style={{ backgroundColor: "#dee2e6" }}></div>
        </Container>
      </section>
      <Container fluid>
        <Row className="">
          <Col md={12} className=" pt-5">
            <Slider {...settings2} className="owl-carousel owl-theme owl-loaded owl-drag">
              {travel.map((items, key) => (
                <div key={key} >
                  <img
                    src={items.image}
                    className={`img-fluid mx-auto mr-4  ${items.cn}`}
                    alt=""
                    width={150}
                    // height={20}
                    // style={{ height: '120px' }}
                  />
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
      <Container>
        {!isMobileView && (<section className="section pt-5desktop-view">
          <Container>
            <Row className="align-items-center">

              <Col lg={5} md={6}>
                <Row className="align-items-center">
                  <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                      <CardBody className="p-0">
                        {/* <img src={HelloKids} className="img-fluid" alt="" width={240} />
                        <div className="overlay-work bg-dark">
                          <div className="play-icon">
                            <Link
                              to="#"
                              onClick={() => setOpen1(true)}
                              className="play-btn btn-sm video-play-icon"
                            >
                              <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="content">
                          <Link to="#" onClick={() => setOpen1(true)} className="title text-white d-block fw-bold">Hello Kids Feedback</Link>
                        </div> */}
                        <div className="position-relative">
                          <img
                            src={HelloKids}
                            className="rounded img-fluid mx-auto d-block shadow rounded"
                            alt=""
                          />
                          <div className="play-icon">
                            <Link
                              to="#"
                              onClick={() => setOpen1(true)}
                              className="play-btn video-play-icon"
                            >
                              <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none' }}></i>
                            </Link>
                          </div>
                        </div>
                        {/* <div className="position-relative">
                          <img
                            src={HelloKids}
                            className="rounded img-fluid mx-auto d-block shadow rounded"
                            alt=""
                          />
                          <div className="play-icon"
                            style={{ width: '40px', height: '40px', borderRadius: '50%', overflow: 'hidden', marginLeft: '80px', opacity: 'o.5' }}>
                            <Link
                              to="#"
                              onClick={() => setOpen1(true)}
                              className="play-btn video-play-icon"
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}
                            >
                              <i
                                className="mdi mdi-play rounded-circle shadow"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  fontSize: '15px',
                                }}
                              ></i>
                            </Link>
                          </div>
                        </div> */}

                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={6} xs={6}>
                    <Row>
                      <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                          <CardBody className="p-0">
                            {/* <img src={CubbyTales} className="img-fluid" alt="" width={240} />
                            <div className="overlay-work bg-dark">
                              <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen2(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <Link to="#" onClick={() => setOpen2(true)} className="title text-white d-block fw-bold">CubbyTales Feedback</Link>
                            </div> */}
                            <div className="position-relative">
                              <img
                                src={CubbyTales}
                                className="rounded img-fluid mx-auto d-block shadow rounded"
                                alt=""
                              />
                              <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen2(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent' }}></i>
                                </Link>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={12} md={12} className="mt-4 pt-2">
                        <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                          <CardBody className="p-0">
                            {/* <img src={LearningCurve} className="img-fluid" alt="" width={220} />
                            <div className="overlay-work bg-dark">
                              <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen3(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <Link to="#" onClick={() => setOpen3(true)} className="title text-white d-block fw-bold">The Learning Curve Feedback</Link>
                            </div> */}
                            <div className="position-relative">
                              <img
                                src={LearningCurve}
                                className="rounded img-fluid shadow rounded"
                                alt=""
                              />
                              <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen3(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none' }}></i>
                                </Link>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col lg={7} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
                <div className="ms-lg-4">
                  <div className="section-title">
                    <h4 className="title mb-4 mt-3">What early education <br />experts are saying about lil Triangle</h4>
                    <p className="text-muted para-desc">Hear it from the experts themselves - lil Triangle app is transforming the landscape of preschool and daycare operations and communication. Trusted by leading educators, lil Triangle is the secret sauce for streamlining tasks, enhancing collaboration, and ensuring seamless communication in the dynamic world of early education. Join the wave of innovation – because when it comes to shaping young minds, lil Triangle is the game-changer endorsed by those in the know!"</p>
                  </div>

                  {/* <div className="mt-4 pt-2">
                                    <Link to="/liltriangle-customers" rel='noopener noreferrer' className="btn btn-primary m-1">Read More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>)}
        {isMobileView && (<section className="section pt-5 mobile-view">
          <Container>
            <Row className="align-items-center">
              <Col lg={7} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
                <div className="ms-lg-4">
                  <div className="section-title">
                    <span className="badge bg-soft-primary rounded-pill fw-bold">About us</span>
                    <h4 className="title mb-4 mt-3">What early education <br />experts are saying about lil Triangle</h4>
                    <p className="text-muted para-desc">Hear it from the experts themselves - lil Triangle app is transforming the landscape of preschool and daycare operations and communication. Trusted by leading educators, lil Triangle is the secret sauce for streamlining tasks, enhancing collaboration, and ensuring seamless communication in the dynamic world of early education. Join the wave of innovation – because when it comes to shaping young minds, lil Triangle is the game-changer endorsed by those in the know!"</p>
                  </div>
                </div>
              </Col>

              <Col lg={5} md={6}>
                <Row className="align-items-center">
                  <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                      <CardBody className="p-0">
                        {/* <img src={HelloKids} className="img-fluid" alt="" />
                        <div className="overlay-work bg-dark">
                          <div className="play-icon">
                            <Link
                              to="#"
                              onClick={() => setOpen1(true)}
                              className="play-btn btn-sm video-play-icon"
                            >
                              <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="content">
                          <Link to="#" onClick={() => setOpen1(true)} className="title text-white d-block fw-bold">Hello Kids Feedback</Link>
                        </div> */}
                        <div className="position-relative">
                          <img
                            src={HelloKids}
                            className="rounded img-fluid mx-auto d-block shadow rounded"
                            alt=""
                          />
                          {/* <div className="play-icon py-4">
                            <Link
                              to="#"
                              onClick={() => setOpen1(true)}
                              className="play-btn video-play-icon"
                            >
                              <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none' }}></i>
                            </Link>
                          </div> */}
                          <div className="play-icon">
                            <Link
                              to="#!"
                              className="btn btn-icon  play-btn video-play-icon  "
                              onClick={() => setOpen1(true)}
                            >
                              <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none', fontSize: '15px', }}></i>
                            </Link>
                          </div>
                        </div>
                        {/* <div className="position-relative">
                          <img
                            src={HelloKids}
                            className="rounded img-fluid mx-auto d-block shadow rounded"
                            alt=""
                          />
                          <div className="play-icon" 
                          style={{ width: '40px', height: '40px', borderRadius: '50%', overflow: 'hidden', marginLeft: '80px', opacity: 'o.5' }}>
                            <Link
                              to="#"
                              onClick={() => setOpen1(true)}
                              className="play-btn video-play-icon"
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}
                            >
                              <i
                                className="mdi mdi-play rounded-circle shadow"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  fontSize: '15px', 
                                }}
                              ></i>
                            </Link>
                          </div>
                        </div> */}
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={6} xs={6}>
                    <Row>
                      <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                          <CardBody className="p-0">
                            {/* <img src={CubbyTales} className="img-fluid" alt="" />
                            <div className="overlay-work bg-dark">
                              <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen2(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <Link to="#" onClick={() => setOpen2(true)} className="title text-white d-block fw-bold">CubbyTales Feedback</Link>
                            </div> */}
                            <div className="position-relative">
                              <img
                                src={CubbyTales}
                                className="rounded img-fluid shadow rounded"
                                alt=""
                              />
                              {/* <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen2(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none' }}></i>
                                </Link>
                              </div> */}
                              <div className="play-icon">
                                <Link
                                  to="#!"
                                  className="btn btn-icon  play-btn video-play-icon  "
                                  onClick={() => setOpen2(true)}
                                >
                                  <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none', fontSize: '15px', }}></i>
                                </Link>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg={12} md={12} className="mt-4 pt-2">
                        <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                          <CardBody className="p-0">
                            {/* <img src={LearningCurve} className="img-fluid" alt="" />
                            <div className="overlay-work bg-dark">
                              <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen3(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <Link to="#" onClick={() => setOpen3(true)} className="title text-white d-block fw-bold">The Learning Curve Feedback</Link>
                            </div> */}
                            <div className="position-relative">
                              <img
                                src={LearningCurve}
                                className="rounded img-fluid shadow rounded"
                                alt=""
                              />
                              {/* <div className="play-icon">
                                <Link
                                  to="#"
                                  onClick={() => setOpen3(true)}
                                  className="play-btn video-play-icon"
                                >
                                  <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none' }}></i>
                                </Link>
                              </div> */}
                              <div className="play-icon">
                                <Link
                                  to="#!"
                                  className="btn btn-icon  play-btn video-play-icon  "
                                  onClick={() => setOpen3(true)}
                                >
                                  <i className="mdi mdi-play rounded-circle shadow" style={{ backgroundColor: 'transparent', border: 'none', fontSize: '15px', }}></i>
                                </Link>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Container>
        </section>)}
      </Container>
      <ModalVideo
        channel="youtube"
        isOpen={open}
        videoId="UISBDll-l0k"
        onClose={() => setOpen(false)}
      />
      <ModalVideo
        channel="youtube"
        isOpen={open1}
        videoId="2VdXHcO2NWA"
        onClose={() => setOpen1(false)}
      />
      <ModalVideo
        channel="youtube"
        isOpen={open2}
        videoId="GXyhQE8tZUc"
        onClose={() => setOpen2(false)}
      />
      <ModalVideo
        channel="youtube"
        isOpen={open3}
        videoId="iudAQheUS00"
        onClose={() => setOpen3(false)}
      />
    </React.Fragment>
  )
}

export default Testimonials