import React, { Component, useState, useEffect } from "react"
import Container from "reactstrap"
import AOS from "aos"
import "../../../node_modules/aos/dist/aos.css"
import Section from "./Section"
import Features from "./Features"
import Testimonials from "./Testimonials"
import ContactUs from "./ContactUs"
import NavbarPage from "../A_LilTriangle/NavbarPage"
import Footr from "./Footer"
import AboutUs from "./AboutUs"

class LilTriangle extends Component {
  constructor(props, context) {
    super(props, context)
    AOS.init()
  }

  state = {
    isMobileView: window.innerWidth < 768,
  }

  componentDidMount() { 
    AOS.refresh()
    document.body.classList = ""
    window.addEventListener("scroll", this.scrollNavigation, true)
    var elementsTitle = document.getElementsByName("sectiontitle")
    var elementsDesc = document.getElementsByName("sectiondesc")

    for (var i = 0; i < elementsTitle.length; i++) {
      elementsTitle[i].setAttribute("data-aos", "fade-up")
      elementsTitle[i].setAttribute("data-aos-duration", "1200")
      elementsDesc[i].setAttribute("data-aos", "fade-up")
      elementsDesc[i].setAttribute("data-aos-duration", "1000")
    }

    //set AOS atttributes to slider <Media> tags
    var elements = document.getElementsByName("clientsreview")
    for (var j = 0; j < elements.length; j++) {
      elements[j].setAttribute("data-aos", "fade-up")
      elements[j].setAttribute("data-aos-duration", "1800")
    }

    //set AOS atttributes to Features
    var features = document.getElementsByName("features")
    for (var f = 0; f < features.length; f++) {
      features[f].setAttribute("data-aos", "fade-up")
      features[f].setAttribute("data-aos-duration", "1800")
    }

    //set AOS atttributes to partners
    // for (var k = 1; k <= 6; k++) {
    //   document
    //     .getElementById("partner" + k)
    //     .setAttribute("data-aos", "fade-up");
    //   document
    //     .getElementById("partner" + k)
    //     .setAttribute("data-aos-duration", "1800");
    // }

    //set AOS atttributes to pricing
    var pricing = document.getElementsByName("jobs")
    for (var a = 0; a < pricing.length; a++) {
      pricing[a].setAttribute("data-aos", "fade-up")
      pricing[a].setAttribute("data-aos-duration", "1400")
    }

    //set AOS atttributes to aboutUs
    var aboutUs = document.getElementsByName("aboutUs")
    for (var a = 0; a < aboutUs.length; a++) {
      aboutUs[a].setAttribute("data-aos", "fade-up")
      aboutUs[a].setAttribute("data-aos-duration", "1400")
    }

    //set AOS atttributes to team
    var team = document.getElementsByName("team")
    for (var b = 0; b < team.length; b++) {
      team[b].setAttribute("data-aos", "fade-up")
      team[b].setAttribute("data-aos-duration", "1200")
    }

    //set AOS atttributes to footer
    var footer = document.getElementsByName("footercolumn")
    for (var fcol = 0; fcol < footer.length; fcol++) {
      footer[fcol].setAttribute("data-aos", "fade-up")
      footer[fcol].setAttribute("data-aos-duration", "1400")
    }

    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true)
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    this.setState({ isMobileView: window.innerWidth < 768 })
  }

  scrollNavigation = () => {
    var doc = document.documentElement
    const navBar = document.getElementById("topnav")
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky")
      } else {
        navBar.classList.remove("nav-sticky")
      }
    }
  }
  render() {
    const { isMobileView } = this.state

    return (
      <React.Fragment>
        <NavbarPage />
        <Section />
        <section className="" id="features">
          <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px' } : {}}>
            <Features />
          </div>
        </section>
        <section className="section bg-light" id="testimonial">
          <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px' } : {}}>
            <Testimonials />
          </div>
        </section>
        <section className="p-5" id="aboutUs">
          <div >
            <AboutUs />
          </div>
        </section>
        <section className=" pb-0" id="contact">
          <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px' } : {}}>
            <ContactUs />
          </div>
        </section>
        <section className="section pb-0">
          <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px', background: '#202942' } : {}}>
            <Footr />
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default LilTriangle
