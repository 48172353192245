// import React, { Component } from "react"
// import { Container } from "reactstrap"

// //Import AOS animation
// import AOS from "aos"
// import "../../../../node_modules/aos/dist/aos.css"
// import NavbarPage from "./Navbar"
// import Footr from "../Footer"
// import PrivacyPolicy from "./PrivacyPolicy"


// class LilTriangle extends Component {
//   constructor(props, context) {
//     super(props, context)
//     //Initilize Aos Animation
//     AOS.init()
//   }

//   componentDidMount() {
//     //Refresh Aos Animation whenever components will render
//     AOS.refresh()

//     document.body.classList = ""
//     window.addEventListener("scroll", this.scrollNavigation, true)

//     //set AOS atttributes to section title and desc
//     var elementsTitle = document.getElementsByName("sectiontitle")
//     var elementsDesc = document.getElementsByName("sectiondesc")

//     for (var i = 0 i < elementsTitle.length i++) {
//       elementsTitle[i].setAttribute("data-aos", "fade-up")
//       elementsTitle[i].setAttribute("data-aos-duration", "1200")
//       elementsDesc[i].setAttribute("data-aos", "fade-up")
//       elementsDesc[i].setAttribute("data-aos-duration", "1000")
//     }
//   }

//   componentWillUnmount() {
//     window.removeEventListener("scroll", this.scrollNavigation, true)
//   }

//   scrollNavigation = () => {
//     var doc = document.documentElement
//     const navBar = document.getElementById("topnav")
//     var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
//     if (navBar != null) {
//       if (top > 80) {
//         navBar.classList.add("nav-sticky")
//       } else {
//         navBar.classList.remove("nav-sticky")
//       }
//     }
//   }
//   render() {
//     return (
//       <React.Fragment>
//         {/* section */}
//         <NavbarPage />

//         <section className="section pb-0" id="aboutUs">
//           <PrivacyPolicy />
//         </section>

//         <section className="section pb-0" id="contact">
//           <Footr />
//         </section>
//       </React.Fragment>
//     )
//   }
// }

// export default LilTriangle


import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import AOS from "aos"
import "../../../../node_modules/aos/dist/aos.css"
import NavbarPage from "./Navbar"
import Footr from "../Footer"
import PrivacyPolicy from "./PrivacyPolicy"

const LilTriangle = () => {

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    // Initialize AOS Animation
    AOS.init()

    // Refresh AOS Animation whenever components will render
    AOS.refresh()

    const handleScroll = () => {
      var doc = document.documentElement
      const navBar = document.getElementById("topnav")
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      if (navBar != null) {
        if (top > 80) {
          navBar.classList.add("nav-sticky")
        } else {
          navBar.classList.remove("nav-sticky")
        }
      }
    }

    window.addEventListener("scroll", handleScroll, true)

    return () => {
      window.removeEventListener("scroll", handleScroll, true)
    }
  }, [])

  return (
    <React.Fragment>
      {/* section */}
      <NavbarPage />

      <section className="section pb-0" id="aboutUs">
        <PrivacyPolicy />
      </section>

      <section className="section pb-0" id="contact">
        <div style={isMobileView ? { paddingLeft: '20px', paddingRight: '20px', background: '#202942' } : {}}>
          <Footr />
        </div>
      </section>
    </React.Fragment>
  )
}

export default LilTriangle
