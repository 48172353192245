import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import SectionTitle from "../../../components/Shared/SectionTitle"
import Slider from "react-slick"

//Import Slick Slider CSS
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import FeatureSlider from '../FeatureSlider'

// import Images
import amico from '../../../assets/images/LilTriangleImages/attendence feature.webp'

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Explore features"
        />
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Scan and Mark Attendance
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  lil Triangle App takes attendance tracking to the next level with its touchless technology.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Location-Based Attendance
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Security is a top priority for Preschools, and lil Triangle App understands that. Our location-based attendance feature ensures that attendance can only be marked within the Preschool premises.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Attendance Reports at Your Fingertips
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Our robust reporting system generates comprehensive attendance reports, giving you insights into attendance patterns. You can access daywise reports to track attendance trends and even view hour-wise data
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Attendance Reports
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  The app generates comprehensive attendance reports, including daywise and hourwise attendance data.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Separate Preschool and Daycare Attendance
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  The app's ability to record Preschool and daycare attendance separately helps Preschool for daycare billing efffectively
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
