import React, { Component } from "react"
import { Container, Row, Col, Form, Button } from "reactstrap"

import social from "../../../assets/images/social/hero.png"
import Enquiry from "../../../assets/images/LilTriangleImages/Enquary.webp"



class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="p-5 bg-half-170 d-table w-100 home-wrapper "
          id="home"
        >
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={6} >
                <div className="title-heading">
                  <h2 className=" my-3">Enquiry Management</h2>
                  <p className="para-desc text-muted mb-0">Powerhouse for effortlessly recording forms, seamless follow-ups, and unlocking the insights you need with precision analytics.</p>
                </div>
              </Col>

              <Col lg={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4 d-flex justify-content-center">
                  <img src={Enquiry} className="img-fluid" alt="" />
                </div>
              </Col>
              <div
                className="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
              </div>
            </Row>
          </Container>
        </section>

      </React.Fragment>
    )
  }
}

export default Section






